import React from 'react'
import './ChangePassword.css'
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {Link , useLocation} from "react-router-dom"
import {BsFillArrowLeftCircleFill} from 'react-icons/bs'




function ChangePassword() {
    const [load, setLoad] = React.useState(false);
    const location = useLocation()
    
    const formik = useFormik({
    initialValues: {
      oldPassword: location.state.old_password,
      newPassword: "",
      
    },
    onSubmit: (values) => {
      setLoad(true)
     const post_data = {
        "oldPassword" : values.oldPassword,
        "newPassword" : values.newPassword,
        "userName" : location.state.selectedUser,
      }
      axios({
        url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/changePassword`,
        method: "put",
        data: post_data
      }).then((res)=>{
        if(res.status === 200 ){
            toast.success(res.data.message)
            setLoad(false)
        }else{
            toast.error(res.data.message)
        }
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
      })
      
      
    },
  });






  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }} >
      <div style={{
        padding: '1rem',
        borderRadius: '8px',
        width: '50%',
        background:'rgb(28, 28, 28)'
      }}>
      <ToastContainer/>
      {load && (
        <div className="mid_loader">
            <h5>Loading...</h5>
        </div>
      )}
            <div className="row p-3">
                    <div className="col-lg-12 p-0">
                    <label htmlFor="oldPassword" className=" col-form-label">Old Password</label>
                    <input type="text" className="col-lg-12 form-control" id="oldPassword" name="oldPassword" required 
                      value={formik.values.oldPassword}
                      onChange={formik.handleChange}
                    />
                
                    </div>
                    <div className="col-lg-12 p-0">
                    <label htmlFor="newPassword" className=" col-form-label">New Password</label>
                    <input type="text" className="col-lg-12 form-control" id="newPassword" name="newPassword" required 
                      
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                    />
                
                    </div>
            </div>
            <div className="d-flex justify-content-between p-1">
                <Link to="/user_profile" className="text-primary btn mr-2"> <BsFillArrowLeftCircleFill/> Back To Home</Link>
                <button  onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }} className="btn-primary btn">Submit</button>
            </div>
      </div>
      
    </div>
  )
}

export default ChangePassword
