import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "fit-content",
    width: "50%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ExtAlbumModal({
  closeModal,
  albumId,
  setAlbumToggle,
  setLoad,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open] = React.useState(true);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    closeModal(false);
  };

  const validationSchema = yup.object({
    label: yup.string().required("*Required Feild"),
    catalogNo: yup.string().required("*Required Feild"),
    upc: yup.string().required("*Required Feild"),
    album_art: yup.mixed().required("File is required"),
  });

  const formik = useFormik({
    initialValues: {
      label: "",
      catalogNo: "",
      upc: "",
      othersInfo: "",
      album_art: "",
      relInBangladesh: false,
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {

      var formData = new FormData();
      formData.append("label", data.label);
      formData.append("catalogNo", data.catalogNo);
      formData.append("upc", data.upc);
      formData.append("othersInfo", data.othersInfo);
      formData.append("album_art", data.album_art);
      formData.append("relInBangladesh", data.relInBangladesh);
      formData.append("albumId", albumId);
      setAlbumToggle(true);
      setLoad(true);
      axios({
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateAlbumInfoWithOutStatus`,
        method: "post",
        data: formData,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setLoad(false);
            closeModal()
          } else {
            setLoad(false);
            toast.error("Something Went Wrong");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
          toast.error("Something Went Wrong");
        });
    },
  });

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="form-group row">
        <label htmlFor="label" className="col-sm-4 col-form-label">
          Label
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control"
            id="label"
            name="label"
            value={formik.values.label}
            onChange={formik.handleChange}
          />
          {formik.errors.label && formik.touched.label ? (
            <div style={{ color: "red" }}>{formik.errors.label}</div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="catalogNo" className="col-sm-4 col-form-label">
          catalogue No.
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control"
            id="catalogNo"
            name="catalogNo"
            value={formik.values.catalogNo}
            onChange={formik.handleChange}
          />
          {formik.errors.catalogNo && formik.touched.catalogNo ? (
            <div style={{ color: "red" }}>{formik.errors.catalogNo}</div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="upc" className="col-sm-4 col-form-label">
          UPC
        </label>
        <div className="col-sm-8">
          <input
            type="number"
            className="form-control"
            id="upc"
            name="upc"
            value={formik.values.upc}
            onChange={formik.handleChange}
          />
          {formik.errors.upc && formik.touched.upc ? (
            <div style={{ color: "red" }}>{formik.errors.upc}</div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="album_art" className="col-sm-4 col-form-label">
          Album Art
        </label>
        <div className="col-sm-8">
          <input
            type="file"
            className="form-control"
            id="album_art"
            name="album_art"
            onChange={(e) => {
              formik.setFieldValue("album_art", e.target.files[0]);
            }}
          />
          {formik.errors.album_art && formik.touched.album_art ? (
            <div style={{ color: "red" }}>{formik.errors.album_art}</div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="othersInfo" className="col-sm-4 col-form-label">
          Others Info
        </label>
        <div className="col-sm-8">
          <textarea
            name="othersInfo"
            className="form-control"
            id="othersInfo"
            cols="30"
            rows="3"
            value={formik.values.othersInfo}
            onChange={formik.handleChange}
          ></textarea>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-8">
          <input
            type="checkbox"
            id="relInBangladesh"
            name="relInBangladesh"
            value={formik.values.relInBangladesh}
            onChange={formik.handleChange}
          />{" "}
          <label htmlFor="relInBangladesh"> Release In Bangladesh</label>
        </div>
      </div>

      <hr />
      <div className="text-center">
        <button
          className="btn btn-success"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />

      <Modal
        theme={classes.palette}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
