import {React, useEffect, useState} from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,

  };
}



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px"
  },
}));

export default function CreatePost({ closeModal}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [load, setLoad] = useState(false);

  const handleClose = () => {
    closeModal(false);
  };


  const validationSchema = yup.object({
    caption: yup.string().required("*Required Feild"),
    link: yup.string().required("*Required Feild"),
    image: yup.mixed().required('File is required'),
  });


  const formik = useFormik({
    initialValues: {
      caption : "",
      link : "",
      image : "",
    },
    validationSchema:validationSchema,
    onSubmit: (value) => {
        setLoad(true)
      let formData = new FormData()
      formData.append('image', value.image)
      formData.append('link', value.link)
      formData.append('caption', value.caption)

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/post/createNewPost`,
        data: formData,
      }).then(res=>{
        if(res.status===200){
            setLoad(false)
          toast.success("New Post Created")
          formik.resetForm();
          
        }else{
          toast.error("Something Went Wrong...")
        }

      }).catch(err=>{
        console.log(err)
        toast.error("Something Went Wrong...")
      })


   
    },
  });

 


  useEffect(()=>{
    getTenYears()
  },[])

  const getTenYears=()=>{
    let currentYear =  new Date().getFullYear();
    let yearList=[]
    for(var i=0;i<10;i++){
      yearList.push(currentYear)
      currentYear--;
    }
   
  }

  const body = ( 
    <div style={modalStyle} className={classes.paper}>
      
      

                
                <div className="form-group row">
                    <label htmlFor="caption" className="col-sm-4 col-form-label">Caption</label>
                    <div className="custom-dropdown-control col-lg-8">
                        <textarea name="caption" className="form-control" id="caption" cols="30" rows="3"
                        value={formik.values.caption}
                        onChange={formik.handleChange}
                        ></textarea>
                        {formik.errors.caption && formik.touched.caption ? (
              <div style={{ color: "red" }}>{formik.errors.caption}</div>
            ) : null}
                    </div>
                </div>
                
                
                <div className="form-group row">
                    <label htmlFor="link" className="col-sm-4 col-form-label">Link</label>
                    <div className="custom-dropdown-control col-lg-8">
                        <input type="text" className="form-control" name="link"
                            value={formik.values.link}
                        onChange={formik.handleChange}
                        />
                        {formik.errors.link && formik.touched.link ? (
              <div style={{ color: "red" }}>{formik.errors.link}</div>
            ) : null}
                    </div>
                </div>

                <div className="form-group row ">
                  <label className="col-form-label col-sm-4" htmlhtmlFor="xlsx_file">
                    Image
                  </label>

                  <div className="col-lg-8">
                  
                  <input
                    className="form-control btn"
                    type="file"
                    id="image"
                    name="image"
                    onChange={(e) => {
                      formik.setFieldValue("image", e.target.files[0]);
                    }}
                    required
                  />
                  {formik.errors.image && formik.touched.image ? (
              <div style={{ color: "red" }}>{formik.errors.image}</div>
            ) : null}
                  </div>
                </div>

              <hr />
              <div className=" text-center">
              
              <button
                  type="button"
                  className="btn btn-primary "
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <b>Create Post</b>
                </button>
               
              </div>
    </div>
  );

  return (
    <div>
    {load && <div className="center">Loading...</div>}
    <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />
      <Modal
      theme={classes.palette}
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        {body}
      </Modal>

    </div>
  );
}
