import {React, useEffect, useState} from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";

function getModalStyle() { 
  const top = 50 ;
  const left = 50 ;

  return { 
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,

  };
}



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px"
  },
}));

export default function UploadDocModal({ closeModal, setReloadTable}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [year,setYear]=useState([]);
  const [load,setLoad]=useState(false);

  const handleClose = () => {
    closeModal(false);
  };


  const validationSchema = yup.object({
    store_name: yup.string().required("*Required Feild"),
    file_year: yup.string().required("*Required Feild"),
    file_month: yup.string().required("*Required Feild"),
    xlsx_file: yup.mixed().required('File is required')
    .test("type", "Only the XLSX format is accepted", (value) => {
      return value && (
          value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
      );
    }),
  });


  const formik = useFormik({
    initialValues: {
      store_name : "",
      file_year : "",
      file_month : "",
      xlsx_file: ""
    },
    validationSchema:validationSchema,
    onSubmit:  async (value) => {
      var formData = new FormData()
      formData.append('file', value.xlsx_file)
      setLoad(true)
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/uploadDoc/${value.store_name}/${value.file_year}/${value.file_month}`,
        data: formData,
      }).then(res=>{
        if(res.status===200){
          toast.success("File Uploaded Successfully")
          setLoad(false)
          setReloadTable(true)
        }else{
          setLoad(false)
          toast.error(res.data?.message)
        }

      }).catch(err=>{
        toast.error(err.message)
        setLoad(false)
      })
      formik.resetForm();
      closeModal(false);
    },
  });

 


  useEffect(()=>{
    getTenYears()
  },[])

  const getTenYears=()=>{
    let currentYear =  new Date().getFullYear();
    let yearList=[]
    for(var i=0;i<10;i++){
      yearList.push(currentYear)
      currentYear--;
    }
    setYear(yearList)
  }

  const body = ( 
    <div style={modalStyle} className={classes.paper}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />

      { load && <div className="w-100 d-flex justify-content-center align-items-center " style={{height: '100%', position:'fixed', top: '0', left: '0', zIndex: 9999}}>
      <CircularProgress/>
      </div>}
      
                <div className="form-group row">
                    <label htmlFor="store_name" className="col-sm-4 col-form-label">Store Name</label>
                    <div className="custom-dropdown-control col-lg-8">
                        <select id="store_name" name="store_name" onChange={formik.handleChange}
                    value={formik.values.store_name} className="custom-select" >
                            <option value="" >--Select stores--</option>
                            <option value="Jiosaavn" >JioSaavn</option>
                            <option value="Wynk" >Wynk</option>
                            <option value="Spotify" >Spotify</option>
                            <option value="Apple" >Apple Music</option>
                            <option value="Gaana" >Gaana</option>
                            <option value="Amazon" >Amazon Music</option>
                            <option value="Hangama" >Hangama</option>
                            <option value="Resso" >Resso</option>
                            <option value="Youtube" >Youtube</option>
                            <option value="Facebook" >Facebook</option>
                            <option value="Tiktok" >Tiktok</option>
                            <option value="Snap" >Snap</option>
                            <option value="Airtel" >Airtel</option>
                            <option value="Idea" >Idea</option>
                            <option value="Voda" >Voda</option>
                            <option value="BSNL" >BSNL</option>
                            <option value="Shadhin" >Shadhin</option>
                            <option value="GPMusic" >GP Music</option>
                            <option value="Vibe" >Vibe</option>
                            <option value="Splash" >Splash</option>
                            
                        </select>
                        {formik.errors.store_name && formik.touched.store_name ? (
              <div style={{ color: "red" }}>{formik.errors.store_name}</div>
            ) : null}
                    </div>
                </div>
                
                <div className="form-group row">
                    <label htmlFor="file_year" className="col-sm-4 col-form-label">Year</label>
                    <div className="custom-dropdown-control col-lg-8">
                        <select id="file_year" name="file_year" className="custom-select" 
                        onChange={formik.handleChange}
                        value={formik.values.file_year}>
                            <option value="" >--select year--</option>
                            {
                              year.map((val,i)=>{
                                return(
                                  <option key={i} value={val} >{val}</option>
                                )
                              })
                            }

                            
                        </select>
                        {formik.errors.file_year && formik.touched.file_year ? (
              <div style={{ color: "red" }}>{formik.errors.file_year}</div>
            ) : null}
                    </div>
                </div>
                
                <div className="form-group row">
                    <label htmlFor="file_month" className="col-sm-4 col-form-label">Month</label>
                    <div className="custom-dropdown-control col-lg-8">
                        <select id="file_month" name="file_month" className="custom-select" 
                        onChange={formik.handleChange}
                        value={formik.values.file_month}>
                            <option value="" >--select month--</option>
                            <option value="Jan" >Jan</option>
                            <option value="Feb" >Feb</option>
                            <option value="Mar" >Mar</option>
                            <option value="Apr" >Apr</option>
                            <option value="May" >May</option>
                            <option value="Jun" >Jun</option>
                            <option value="Jul" >Jul</option>
                            <option value="Aug" >Aug</option>
                            <option value="Sep" >Sep</option>
                            <option value="Oct" >Oct</option>
                            <option value="Nov" >Nov</option>
                            <option value="Dec" >Dec</option>
                        </select>
                        {formik.errors.file_month && formik.touched.file_month ? (
              <div style={{ color: "red" }}>{formik.errors.file_month}</div>
            ) : null}
                    </div>
                </div>

                <div className="form-group row ">
                  <label className="col-form-label col-sm-4" htmlFor="xlsx_file">
                    Upload xlsx File
                  </label>

                  <div className="col-lg-8">
                  
                  <input
                    className="form-control btn"
                    type="file"
                    id="xlsx_file"
                    name="xlsx_file"
                    onChange={(e) => {
                      formik.setFieldValue("xlsx_file", e.target.files[0]);
                    }}
                    required
                  />
                  {formik.errors.xlsx_file && formik.touched.xlsx_file ? (
              <div style={{ color: "red" }}>{formik.errors.xlsx_file}</div>
            ) : null}
                  </div>
                </div>

              <hr />
              <div className=" text-center">
              
              <button
                  type="button"
                  className="btn btn-primary "
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <b>Send</b>
                </button>
               
              </div>
    </div>
  );

  return (
    <div>

      <Modal
      theme={classes.palette}
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        {body}
      </Modal>

    </div>
  );
}
