import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { FcMusic } from "react-icons/fc";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "fit-content",
    width: "50%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ExtSongModal({
  closeModal,
  songDetails,
  setLoad,
  setSelectedSong,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open] = React.useState(true);

  const handleClose = () => {
    console.log("song", songDetails);
    closeModal(false, songDetails);
  };

  const validationSchema = yup.object({
    isrc: yup.string().required("*Required Feild"),
    trackDuration: yup.string().required("*Required Feild"),
  });

  const songFormik = useFormik({
    initialValues: {
      isrc: "",
      trackDuration: "",
      songId: songDetails._id,
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      addInSong(data);
    },
  });

  const addInSong = (details) => {
    setLoad(true);
    console.log(details);
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateSongInfo`,
      method: "post",
      data: details,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(res);
          setSelectedSong((prev) => [...prev, res?.data?.data?._id]);

          setLoad(false);
          handleClose();
          toast.success("Saved");
        } else {
          setLoad(false);
          toast.error("Something Went Wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="title p-1">
        <h5 className="p-0 m-0">
          {" "}
          <FcMusic /> {songDetails.song_title}
        </h5>
      </div>
      <br />

      <div className="form-group row">
        <label htmlFor="isrc" className="col-sm-4 col-form-label">
          ISRC
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control"
            id="isrc"
            name="isrc"
            value={songFormik.values.isrc}
            onChange={songFormik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="trackDuration" className="col-sm-4 col-form-label">
          Track Duration
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control"
            id="trackDuration"
            name="trackDuration"
            placeholder="HH:MM:SS"
            value={songFormik.values.trackDuration}
            onChange={songFormik.handleChange}
          />
        </div>
      </div>

      <div className="text-center">
        <button
          className="btn btn-success"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            songFormik.handleSubmit();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />

      <Modal
        theme={classes.palette}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
