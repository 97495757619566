import React from 'react'
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {AiFillEye} from "react-icons/ai"
import { useNavigate } from 'react-router-dom';

function SongForm({formData, updateCred}) {

    const nav = useNavigate()

    const formik = useFormik({
    initialValues: {
      song_title: formData.SongDetails.song_title,
      song_type: formData.SongDetails.song_type,
      language: formData.SongDetails.language,
      song_desc: formData.SongDetails.song_desc,
      gener: formData.SongDetails.gener,
      sub_gener: formData.SongDetails.sub_gener,
      mood: formData.SongDetails.mood,
      singer: formData.SongDetails.singer,
      composer: formData.SongDetails.composer,
      director: formData.SongDetails.director,
      star_cast: formData.SongDetails.star_cast,
      explicit: formData.SongDetails.explicit,
      lyricist: formData.SongDetails.lyricist,
      wav_file: formData.SongDetails.wav_file_url,
      noc_doc: formData.SongDetails.noc_doc_url,
      upload_mp3: formData.SongDetails?.upload_mp3_url,
      crbt_start: formData.SongDetails.crbt_start,
      crbt_name: formData.SongDetails.crbt_name,
    },
    onSubmit: (values) => {

      Object.keys(values).forEach(key => {
        if (values[key] === undefined) {
          delete values[key];
        }
      });
      values.songId = updateCred.songId
      values.notificationId = updateCred.notifyId
      axios({
        url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateSongDetails`,
        method: "post",
        data: values
      }).then((res)=>{
        nav("/action", {state : {
          message : "Great.. Your Song Details has been updated",
          status : "success" 
        }})
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
      })
      
      
    },
  });




  return (
    <>
    <ToastContainer />
      <div className="form_box">
              <div className="form_container shadow">
                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" d-flex flex-column col-lg-5">
                    {formik.errors.song_title &&
                    formik.touched.song_title ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.song_title}
                      </div>
                    ) : null}
                    <label htmlFor="song_title">
                      {" "}
                      Song Title <span className="red">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="song_title"
                      name="song_title"
                      onChange={formik.handleChange}
                      value={formik.values.song_title}
                      required
                    />
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.song_type &&
                    formik.touched.song_type ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.song_type}
                      </div>
                    ) : null}
                    <label htmlFor="song_type">
                      <span className="red">*</span> Song Type
                    </label>
                    <select
                      id="song_type"
                      name="song_type"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.song_type}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Original">Original</option>
                      <option value="Cover">Cover</option>
                      <option value="Remix">Remix</option>
                      <option value="Medley">Medley</option>
                      <option value="Live">Live</option>
                      <option value="Karaoke">Karaoke</option>
                      <option value="DJ">DJ</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.language &&
                    formik.touched.language ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.language}
                      </div>
                    ) : null}
                    <label htmlFor="language">
                      Language <span className="red">*</span>{" "}
                    </label>
                    <select
                      id="language"
                      name="language"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.language}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Bengali">Bengali</option>
                      <option value="English">English</option>
                      <option value="Santali">Santali</option>
                      <option value="Hindi">Hindi</option>
                      <option value="Gujarati">Gujarati</option>
                      <option value="Marathi">Marathi</option>
                      <option value="Bhojpuri">Bhojpuri</option>
                      <option value="Tamil">Tamil</option>
                      <option value="Telegu">Telegu</option>
                      <option value="Kannada">Kannada</option>
                      <option value="Malayalam">Malayalam</option>
                      <option value="Punjabi">Punjabi</option>
                      <option value="Assamese">Assamese</option>
                      <option value="Oriya">Oriya</option>
                      <option value="Odia">Odia</option>
                      <option value="Manipuri">Manipuri</option>
                      <option value="Haryanvi">Haryanvi</option>
                      <option value="Sanskrit">Sanskrit</option>
                      <option value="Rajasthani">Rajasthani</option>
                      <option value="Sambalpuri">Sambalpuri</option>
                      <option value="Arbic">Arbic</option>
                      <option value="Urdu">Urdu</option>
                      <option value="Banjara">Banjara</option>
                      <option value="Nepali">Nepali</option>
                      <option value="Maithili">Maithili</option>
                      <option value="Garhwali">Garhwali</option>
                      <option value="Sainthili">Sainthili</option>
                      <option value="Himachali">Himachali</option>
                      <option value="Konkani">Konkani</option>
                      <option value="Javanese">Javanese</option>
                      <option value="Awadhi">Awadhi</option>
                      <option value="Naga">Naga</option>
                      <option value="Khasi">Khasi</option>
                      <option value="Dogri">Dogri</option>
                      <option value="Persian">Persian</option>
                      <option value="Pali">Pali</option>
                      <option value="Tibetan">Tibetan</option>
                      <option value="Mandarin">Mandarin</option>
                      <option value="Dogri">Dogri</option>
                      <option value="Chhattisgarhi">Chhattisgarhi</option>
                      <option value="Nagpuri">Nagpuri</option>
                      <option value="Kashmiri">Kashmiri</option>
                      <option value="Sindhi">Sindhi</option>
                      <option value="Kumauni">Kumauni</option>
                      <option value="Marwari">Dogri</option>
                      <option value="Sinhala">Sinhala</option>
                      <option value="Turkish">Turkish</option>
                      <option value="Kokborok">Kokborok</option>
                      <option value="Instrumental">Instrumental</option>
                    </select>
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.gener &&
                    formik.touched.gener ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.gener}
                      </div>
                    ) : null}
                    <label htmlFor="gener">
                      Genre <span className="red">*</span>{" "}
                    </label>
                    <select
                      id="gener"
                      name="gener"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.gener}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Film">Film</option>
                      <option value="Devotional">Devotional</option>
                      <option value="Traditional">Traditional</option>
                      <option value="Indie Pop">Indie Pop</option>
                      <option value="Instrumental">Instrumental</option>
                      <option value="Western Classical">
                        Western Classical
                      </option>
                      <option value="Carnatic Classical">
                        Carnatic Classical
                      </option>
                      <option value="Hindusthani Classical">
                        Hindusthani Classical
                      </option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="English Pop">English Pop</option>
                      <option value="Ghazal">Ghazal</option>
                      <option value="Regional Pop">Regional Pop</option>
                      <option value="Lounge">Lounge</option>
                      <option value="Fusion">Fusion</option>
                      <option value="Electronic">Electronic</option>
                      <option value="HipHop">HipHop</option>
                      <option value="Rap">Rap</option>
                      <option value="Rock">Rock</option>
                      <option value="Blues">Blues</option>
                      <option value="Metal">Metal</option>
                      <option value="Jazz">Jazz</option>
                    </select>
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.sub_gener &&
                    formik.touched.sub_gener ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.sub_gener}
                      </div>
                    ) : null}
                    <label htmlFor="sub_gener">
                      Sub Genre <span className="red">*</span>{" "}
                    </label>
                    <select
                      id="sub_gener"
                      name="sub_gener"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.sub_gener}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Alternative">Alternative</option>
                      <option value="Traditional">Traditional</option>
                      <option value="Contemporary">Contemporary</option>
                      <option value="Acoustic">Acoustic</option>
                      <option value="experimental">experimental</option>
                      <option value="Progressive">Progressive</option>
                      <option value="Psychedelic">Psychedelic</option>
                      <option value="Indie Folk">Indie Folk</option>
                      <option value="Rabindra Sangee">Rabindra Sangee</option>
                      <option value="Nazrul Geeti">Nazrul Geeti</option>
                      <option value="Rajanikanta">Rajanikanta</option>
                      <option value="Geeti">Geeti</option>
                      <option value="Baul Geet">Baul Geet</option>
                      <option value="Borgeet">Borgeet</option>
                      <option value="Bihu">Bihu</option>
                      <option value="DJ Mix">DJ Mix</option>
                      <option value="LoFi">LoFi</option>
                      <option value="Mapilla">Mapilla</option>
                      <option value="Karwa Chaut Songs">
                        Karwa Chaut Songs
                      </option>
                      <option value="Bhajan">Bhajan</option>
                      <option value="Geet">Geet</option>
                      <option value="Langa Geet">Langa Geet</option>
                      <option value="Kirtan">Kirtan</option>
                      <option value="Nepali">Nepali</option>
                      <option value="MaitMantrahili">Mantra</option>
                      <option value="Chalisa">Chalisa</option>
                      <option value="Marriage Song">Marriage Song</option>
                      <option value="Raksha Bandhan">Raksha Bandhan</option>
                      <option value="Naat">Naat</option>
                      <option value="Qawwals">Qawwals</option>
                      <option value="Soundtrack">Soundtrack</option>
                      <option value="Naga">Naga</option>
                      <option value="Khasi">Khasi</option>
                      <option value="Dogri">Dogri</option>
                      <option value="Persian">Persian</option>
                      <option value="Pali">Pali</option>
                      <option value="Tibetan">Tibetan</option>
                      <option value="Mandarin">Mandarin</option>
                      <option value="Dogri">Dogri</option>
                      <option value="Chhattisgarhi">Chhattisgarhi</option>
                      <option value="Nagpuri">Nagpuri</option>
                      <option value="Kashmiri">Kashmiri</option>
                      <option value="Sindhi">Sindhi</option>
                      <option value="Kumauni">Kumauni</option>
                      <option value="Marwari">Dogri</option>
                      <option value="Sinhala">Sinhala</option>
                      <option value="Turkish">Turkish</option>
                      <option value="Kokborok">Kokborok</option>
                      <option value="Instrumental">Instrumental</option>
                    </select>
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.mood &&
                    formik.touched.mood ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.mood}
                      </div>
                    ) : null}
                    <label htmlFor="mood">
                      <span className="red">*</span>Mood
                    </label>
                    <select
                      id="mood"
                      name="mood"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.mood}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Romantic">Romantic</option>
                      <option value="Happy">Happy</option>
                      <option value="Sad">Sad</option>
                      <option value="Dance">Dance</option>
                      <option value="Bhangra">Bhangra</option>
                      <option value="Patriotic">Patriotic</option>
                      <option value="Nostalgic">Nostalgic</option>
                      <option value="Inspirational">Inspirational</option>
                      <option value="Enthusiastic">Enthusiastic</option>
                      <option value="Optimistic">Optimistic</option>
                      <option value="Passion">Passion</option>
                      <option value="Pessimistic">Pessimistic</option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="Fusion">Fusion</option>
                      <option value="Peppy">Peppy</option>
                      <option value="HipHop">HipHop</option>
                      <option value="Philosophical">Philosophical</option>
                      <option value="Mellow">Mellow</option>
                      <option value="Calm">Calm</option>
                    </select>
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-12">
                    <label htmlFor="song_desc"> Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Write About Your Song"
                      id="song_desc"
                      name="song_desc"
                      onChange={formik.handleChange}
                      value={formik.values.song_desc}
                    ></textarea>
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.singer &&
                    formik.touched.singer ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.singer}
                      </div>
                    ) : null}
                    <label htmlFor="singer">
                      Singer <span className="red">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="singer"
                      name="singer"
                      onChange={formik.handleChange}
                      value={formik.values.singer}
                      required
                    />
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.composer &&
                    formik.touched.composer ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.composer}
                      </div>
                    ) : null}
                    <label htmlFor="composer">
                      <span className="red">*</span> Composer
                    </label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="composer"
                      name="composer"
                      onChange={formik.handleChange}
                      value={formik.values.composer}
                      required
                    />
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="director">Director</label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="director"
                      name="director"
                      onChange={formik.handleChange}
                      value={formik.values.director}
                    />
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="star_cast">Star Cast</label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="star_cast"
                      name="star_cast"
                      onChange={formik.handleChange}
                      value={formik.values.star_cast}
                    />
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="explicit">Explecit</label>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="explicit"
                        id="explicit_yes"
                        onChange={formik.handleChange}
                        value="yes"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="explicit_yes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="explicit"
                        id="explicit_no"
                        onChange={formik.handleChange}
                        value="no"
                      />
                      <label className="form-check-label" htmlFor="explicit_no">
                        No
                      </label>
                    </div>
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="lyricist"> Lyricist</label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="lyricist"
                      name="lyricist"
                      onChange={formik.handleChange}
                      value={formik.values.lyricist}
                    />
                  </div>
                </div>

                <div className="crbt-box">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div className=" d-flex flex-column col-lg-5">
                      <label htmlFor="crbt_start">CRBT Start Time</label>
                      <input
                        type="text"
                        placeholder="HH:MM:SS"
                        className="form-control col-lg-12"
                        id="crbt_start"
                        name="crbt_start"
                        value={formik.values.crbt_start}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className=" d-flex flex-column col-lg-5">
                      <label htmlFor="crbt_name"> CRBT name</label>
                      <input
                        type="text"
                        className="form-control col-lg-12"
                        id="crbt_name"
                        name="crbt_name"
                        value={formik.values.crbt_name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>

                
                <div className=" d-flex justify-content-between align-items-center mt-4">
                  <label className="d-flex col-lg-4" htmlFor="wav_file">
                     Wav File 
                  </label>
                  
                  {/* <input
                    className="form-control btn col-lg-6"
                    type="file"
                    id="wav_file"
                    name="wav_file"
                    onChange={(e) => {
                      formik.setFieldValue("wav_file", e.target.files[0]);
                    }}
                    required
                  /> */}



                  {
                    formData.SongDetails.wav_file_url && <button onClick={()=>{window.open(formData.SongDetails.wav_file_url)}} target="_" className="btn btn-outline-info col-lg-2"><AiFillEye/> view</button>
                  }
                  
                  
                </div>
                <div className=" d-flex justify-content-between align-items-center mt-2">
                  <label className="d-flex col-lg-4" htmlFor="noc_doc">
                     NOC
                  </label>
                  {/* <input
                    className="form-control btn col-lg-6"
                    type="file"
                    id="noc_doc"
                    name="noc_doc"
                    onChange={(e) => {
                      formik.setFieldValue("noc_doc", e.target.files[0]);
                    }}
                  /> */}


                    {
                      formData.SongDetails.noc_doc_url && <button onClick={()=>{window.open(formData.SongDetails.noc_doc_url)}} target="_" className="btn btn-outline-info col-lg-2"><AiFillEye/> view</button>
                    }


                  
                </div>
               
                {formik.errors.wav_file &&
                    formik.touched.wav_file ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.wav_file}
                      </div>
                    ) : null}
                <br />
                <hr />
                <div className=" d-flex justify-content-center align-items-center mt-2">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
              
            </div>
    </>
  )
}

export default SongForm
