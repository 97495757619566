import React from 'react'
import "./AdminPanel.css"
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaUserAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ADMIN } from '../../utils/role';

function AdminPanel() {

  const [isFc, setIsfc] = React.useState(false)
  const role = localStorage.getItem("role");

  const nav = useNavigate();
  const logoutHandeler = () => {
    localStorage.removeItem("admin_token_");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    nav("/")
  }


  function fullscreenHandler() {
    setIsfc(!isFc)
    if (isFc)
      document.body.requestFullscreen();
    else
      document.exitFullscreen();
  }

  return (
    <>

      <nav className="navbar navbar-expand-lg navbar-custom navbar-dark fixed-top">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">M-Tool Admin</NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/admin_home" style={({ isActive }) => { return { color: isActive ? 'white' : 'rgba(255,255,255,.5)' } }}>Home</NavLink>
              </li>
              {role === ADMIN && <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/create_artist" style={({ isActive }) => { return { color: isActive ? 'white' : 'rgba(255,255,255,.5)' } }}>Create Artist</NavLink>
              </li>}

              {role === ADMIN && <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/credit_notes" style={({ isActive }) => { return { color: isActive ? 'white' : 'rgba(255,255,255,.5)' } }}>Credit Notes</NavLink>
              </li>}

              {role === ADMIN && <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/user_profile" style={({ isActive }) => { return { color: isActive ? 'white' : 'rgba(255,255,255,.5)' } }}>User Profile</NavLink>
              </li>}


              {/* <li className="nav-item">
        <NavLink className="nav-link" aria-current="page" to="/" style={({isActive})=>{return{color: isActive ? 'white' : 'rgba(255,255,255,.5)'}}}>Home</NavLink>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown link
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a className="dropdown-item" href="/">Action</a></li>
            <li><a className="dropdown-item" href="/">Another action</a></li>
            <li><a className="dropdown-item" href="/">Something else here</a></li>
          </ul>
        </li> */}
            </ul>
            <div className="nav_right ">
              <ul className="navbar-nav ms-auto p-2 ">
                <li className="nav-item dropdown no-arrow mx-1">

                  {/* <!-- Dropdown - Alerts --> */}
                  <div
                    className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="alertsDropdown"
                  >


                    <div className="dropdown-item text-center small text-gray-500">

                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown no-arrow">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                      {localStorage.getItem("user")}
                    </span>
                    <FaUserAlt />
                  </a>

                  <div
                    className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >



                    <button
                      className="dropdown-item"
                      onClick={logoutHandeler}
                      data-toggle="modal"
                      data-target="#logoutModal"
                    >
                      Logout
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={fullscreenHandler}
                      data-toggle="modal"
                      data-target="#logoutModal"
                    >
                      {
                        !isFc ? "Exit fullscreen" : "Fullscreen mode"
                      }

                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>


      <div className="admin_outlet">
        <Outlet />
      </div>


    </>
  )
}

export default AdminPanel