import React, { useState } from "react";
import "./ApprovedTable.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@material-ui/core/Button";
import { IoIosSave } from "react-icons/io";
import ExtAlbumModal from "./ExtAlbumModal";
import ExtSongModal from "./ExtSongModal";
import axios from "axios";

function SendReleaseDetails({ album, setShowDetails, refreshTable }) {
  const [load, setLoad] = useState(false);
  const [albumModal, setAlbumModal] = useState(false);
  const [songDetails, setSongDetails] = useState(false);

  const [albumToggle, setAlbumToggle] = useState(false);
  const [selectedSong, setSelectedSong] = useState([]);

  const handleFinalSubmit = () => {
    setLoad(true);
   

    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateAlbumStatusToRelsesed/${album._id}`,
      method: "POST",
     
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setLoad(false);
          refreshTable();
          setShowDetails(true);
          toast.success("Released");
        } else {
          setLoad(false);
          toast.error("Something Went Wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
        toast.error("Something Went Wrong");
      });
  };

  const closeModal = (data, selectedSong) => {
    setSongDetails(data);
  };
  const closeAlbumModal = () => {
    setAlbumModal(false);
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />
      {load && <div className="center">Loading...</div>}

      <div className="release_details_container">
        <div className="title text-center text-dark">
          <h5>Insert Extra Information</h5>
          <small>{album.title}</small>
        </div>

        <div className="release_item">
          <div>
            <p>Insert Album Information</p>
            <h4>
              <b>{album.title}</b>
            </h4>
          </div>
          <div className="right_div_of_release">
            <div className="mx-4 d-flex align-items-center gap-2 status_div">
              <div className="circle_status"></div>
              {albumToggle ? "Sent" : "Pending"}
            </div>
            {!albumToggle && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAlbumModal(true);
                  }}
                >
                  Insert
                </Button>
              </div>
            )}
          </div>
        </div>

        {album.all_song.map((song, idx) => {
          return (
            <div key={idx} className="release_item">
              <div>
                <p>Insert Song Information</p>
                <h4>
                  <b>{song.song_title}</b>
                </h4>
              </div>
              <div className="right_div_of_release">
                <div className="mx-4 d-flex align-items-center gap-2 status_div">
                  {selectedSong.includes(song?._id) ? (
                    <>
                      <div className="circle_status"></div>Sent
                    </>
                  ) : (
                    <>
                      <div className="circle_status"></div>Pending
                    </>
                  )}
                </div>
                {selectedSong.includes(song?._id) ? (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled
                      onClick={(e) => {
                        e.preventDefault();
                        setSongDetails(song);
                      }}
                    >
                      Insert
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setSongDetails(song);
                      }}
                    >
                      Insert
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <div className="text-start final_submit_for_release">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleFinalSubmit}
          >
            <IoIosSave /> Save
          </Button>
        </div>

        {albumModal && (
          <ExtAlbumModal
            closeModal={closeAlbumModal}
            albumId={album._id}
            setAlbumToggle={setAlbumToggle}
            setLoad={setLoad}
          />
        )}

        {songDetails && (
          <ExtSongModal
            setSelectedSong={setSelectedSong}
            closeModal={closeModal}
            songDetails={songDetails}
            setLoad={setLoad}
          />
        )}
      </div>
    </div>
  );
}

export default SendReleaseDetails;
