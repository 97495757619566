import React from 'react'
import './Support.css'
import FaqAcc from './FaqAcc'
import { Box } from '@material-ui/core'
import axios from 'axios';
function Support() {
  const [faq, setFaq] = React.useState([])
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_DOMAIN_KEY}/api/support/getFAQ`)
    .then(res => {
      setFaq(res?.data?.faq)
    })
    .catch(err => {
      console.log(err)
    })
  },[])
  return (
    <div style={{
      marginTop: '50px',
      width: '100%',
      position : 'relative',
      minHeight : '80vh',
    }}>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Support</h1>
      </div>
      
      <Box paddingBottom={10}>
      {
        faq.map((item, index) => {
          return (
            <FaqAcc
              key={index}
              title={item?.title}
              subtitle={item?.subTitle}
              description={item?.description}
              link={item?.link}
              linkName={item?.buttonName}
            />
          )
        })
      }
      </Box>

      <footer className="support_footer" >
            <div className="left">
              Copyright © 2022 All Rights Reserved by Bengal Pictures
            </div>
            <div className="right">
                Developed By <a target="_" href="https://github.com/Sdalpha">Satyajit Dutta</a> & <a target="_" href="https://github.com/sagarsamanta">Sagar Samanta</a>
            </div>
      </footer>
    </div>
  )
}

export default Support