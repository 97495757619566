import {React, useState} from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import {Outlet} from "react-router-dom";




function Main() {

  const [showSidebar, setShowSidebar] = useState(true);

  const handleToggleTop=()=>{
    setShowSidebar(!showSidebar)
  }





  return (
    <div>

      <div id="wrapper">
      {/* <!-- Sidebar ===============================--> */}
      {
        showSidebar && <Sidebar  />
      }
        
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/* <!-- Navbar ===============================--> */}
            <Navbar handleToggleTop={handleToggleTop} />
            {/* <!-- Begin Page Content --> */} 
            <div className="container-fluid position-relative">
              
              {/* ======================Inner Pages======================== */}
              
              
              

              <Outlet/>


            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}


    </div>
  );
}

export default Main;
