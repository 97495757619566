import React, { useEffect, useState } from "react";
import "./UserProfile.css";
import Select from "react-select";
import axios from "axios";
import searchGif from "../../../asset/images/search.gif"
import { ToastContainer, toast } from "react-toastify";
import userImage from "../../../asset/images/man.png";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillDatabase } from "react-icons/ai";
import { IoIosSave } from "react-icons/io";
import { Link } from "react-router-dom";

// =================

import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

// ========

import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function UserProfile() {
  const [userList, setUserList] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [load, setLoad] = useState(false);
  const [albumList, setAlbumList] = useState();
  const [genCat,setGenCat] = useState();
  const [crbtCat,setCrbtCat] = useState();
  const [bngCat,setBanCat] = useState();
  const classes = useStyles();

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllArtistName`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          let userList = [];
          res.data.forEach((val) => {
            userList.push({ value: val, label: val });
          });
          setUserList(userList);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Somthing Went Wrong");
      });
  }, []);

  //   Get user Details api call ======
  const getUserDetails = (UserName) => {
    setLoad(true)
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getUserProfile/${UserName.value}`,
      method: "get",
    })
      .then((res) => {
        if(res.status===200){
          setLoad(false)
          setUserDetails(res.data.user);
          setAlbumList(res.data.albumList)
          setGenCat(res.data?.user.generalCategory)
          setCrbtCat(res.data?.user.crbtCategory)
          setBanCat(res.data?.user.bangladeshCategory)
        }else{
          toast.error("Something went wrong, please try again...")
         
        } 
      })
      .catch((err) => {
        console.log(err);
        toast.error("Somthing Went Wrong");
      });
  };

  const handleStoreControl = () => {
    setLoad(true)
    const post_data = {
            "userName": userDetails.userName,
            "generalCategory": genCat,
            "crbtCategory": crbtCat,
            "bangladeshCategory": bngCat
    }

    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateStoreAccess`,
      method: "post",
      data: post_data
    })
      .then((res) => {
          if(res.status === 200){
           
            setLoad(false)
            toast.success("Profile Saved")
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Somthing Went Wrong");
      });

  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />

      {load && (
        <div className="mid_loader">
            <h5>Loading...</h5>
        </div>
      )}

      <div className="row">
        {/* Left PART */}
        {userDetails ? (
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-4 border-right d-flex align-items-center justify-content-between">
                <img
                  src={userImage}
                  alt=""
                  style={{ height: "100px", width: "auto" }}
                />
                <div>
                  <b>User Name</b>
                  <h5>{userDetails.userName}</h5>
                </div>
              </div>
              <div className="col-lg-4 border-right ">
                <b>Royalties Name</b> : {userDetails.royalties_name} <br />
                <b>Designation</b> : {userDetails.designation} <br />
                <b>Client Type</b> : {userDetails.client_type} <br />
                <b>Country</b> : {userDetails.country} <br />
                
              </div>
              <div className="col-lg-4 ">
                <b>Address</b> : {userDetails.full_address} <br />
                <b>Phone</b> : {userDetails.phone_number} <br />
                <b>Email</b> : {userDetails.email}
              </div>

            </div>

            {userDetails.label_name && (
              <div className="label_section">
                <h2>LABEL</h2>
                <h4>{userDetails.label_name}</h4>
              </div>
            )}

            <div className="change_password">
              <div className="col-lg-2 admin_icon">
                <RiLockPasswordFill />
              </div>
              <div className="col-lg-6 ">
                <h5>
                  <strong>Change User Password</strong>
                </h5>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
              </div>
              <div className="col-lg-4">
                <Link
                  className="btn btn-primary"
                  to="/change_password"
                  state={{
                    selectedUser: userDetails.userName,
                    old_password: userDetails.password,
                  }}
                >
                  Change Password
                </Link>
              </div>
            </div>

            {/* STORE CONTROLL */}
            <div className="row store_control">
              <div className="col-lg-2 admin_icon">
                <AiFillDatabase />
              </div>
              <div className="col-lg-2 text-primary">
                <h5>
                  <strong>Store Control For Analytics</strong>
                </h5>
              </div>

              <div className="col-lg-8">
                <FormControl component="fieldset">
                  <FormGroup
                    row
                    aria-label="position"
                    name="storeControl"
                    defaultValue="general"
                  >
                    <FormControlLabel
                      value="general"
                      control={<Checkbox color="primary" />}
                      label="General Section"
                      labelPlacement="top"
                      checked={genCat}
                      onChange={(e)=>{
                        setGenCat(e.target.checked)
                      }}
                    />
                    <FormControlLabel
                      value="crbt"
                      control={<Checkbox color="primary" />}
                      label="CRBT Section"
                      labelPlacement="top"
                      checked={crbtCat}
                      onChange={(e)=>{
                        setCrbtCat(e.target.checked)
                      }}
                    />
                    <FormControlLabel
                      value="bangladesh"
                      control={<Checkbox color="primary" />}
                      label="Bangladesh Section"
                      labelPlacement="top"
                      checked={bngCat}
                      onChange={(e)=>{
                        setBanCat(e.target.checked)
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>

            

            {/* ALBUM LIST */}

            <div className=" album_list">
              <div className="title mb-2 text-primary">
                <h4>Album List</h4>
              </div>

             
                           
                            
                            {

albumList.map((album,idx)=>{
    return(
        <Accordion key={idx} style={{background: '#2e2e2e',color:'rgb(209, 209, 209)'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}> <strong>{album.title}</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="row">
          {console.log(album.relInBangladesh)}
            <p className="col-lg-4"><b>Status</b></p>
            <p className="col-lg-8">{album.status==="SUCCESS" ? "Approved" : album.status.charAt(0).toUpperCase() + album.status.slice(1).toLowerCase() }</p>
            
            <p className="col-lg-4"><b>Live Date</b></p>
            <p className="col-lg-8">{album.live_date}</p>
            
            <p className="col-lg-4"><b>Number Of Songs</b></p>
            <p className="col-lg-8">{album.all_song.length}</p>
            
            <p className="col-lg-4"><b>Release In Bangladesh</b></p>
            <p className="col-lg-8">{album.relInBangladesh ? "Yes" : "No"}</p>
            
            <p className="col-lg-4"><b>Release Date</b></p>
            <p className="col-lg-8">{album.release_date}</p>

          </div>
          </Typography>
        </AccordionDetails>
        </Accordion>
        )
                })
              }
      
      
                   
             
            </div>



            {/* save store controll button */}
            <div className="text-end">
              <button className="btn btn-primary" onClick={handleStoreControl}>
                <IoIosSave /> Save
              </button>
            </div>




          </div>
        ) : (
          <>
            <div className="middle col-lg-9">
              <img style={{height: '150px',width:'auto'}} src={searchGif} alt="" /> 
              <h5>Search Artist's Profile</h5>
              <p>Select or search artist from top of the side panel. </p>
            </div>
          </>
        )}
        {/* End of left */}

        {/* Right PART */}
        <div
          className="col-lg-3"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            height: "100%",
            background: "#0a0a0a",
            paddingTop: "100px",
          }}
        >
          <div className="search_box">
            <div className="input_row">
            <label htmlFor="selectUser">Search Artist</label>
              <Select options={userList} onChange={(e) => getUserDetails(e)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
