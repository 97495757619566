import React, {useEffect} from 'react';
import "./modal.css";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from "@material-ui/core/CircularProgress";
import { MdDescription } from "react-icons/md";
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function getModalStyle() {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      height: '80%',
      width: '50%',
    };
  }
  
  
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: "white",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
      [theme.breakpoints.only('xs')]: {
        width: '90% !important',
      },
    },
   
  }));

export default function DetailsModal( {closeModal, songDetails, albumDetails}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const [data, setData] = React.useState();
  const handleClose = () => {
    closeModal(false);
  };




  const getImage = () => {
    let albumId = "";
    if (songDetails) {
      albumId = songDetails.albumId;
    } else {
      albumId = "";
    }
    if (!albumId) return;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getSongDetais/${albumId}`,
    })
      .then((response) => {
        setData(response.data.url);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
        toast.error("Something Went Wrong")
      });
  };

  useEffect(() => {
    setLoad(true);
    getImage();
    return () => {
      setData();
    };
  }, []);



  function linkConverter(info){
    let urls = info.split("https://").map(val => { return "https://"+val }).slice(1)
    return urls  
  } 


  const body =(
    <div style={modalStyle} className={classes.paper}>
        <div className="main">
              <div className="upper">
                <div className="left">
                  {load && (
                    <div className="d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </div>
                  )}
                  {data ? (
                    <img src={data} alt="Album Art Not Found" />
                  ) : (
                    <img
                      src="https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png"
                      alt="not found"
                    />
                  )}
                </div>

                <div className="right">
                  {<h2>{songDetails?.song_title}</h2>}
                  <p>
                    Sung by {songDetails?.singer} , Compose by{" "}
                    {songDetails?.composer} & Written by {songDetails?.lyricist}{" "}
                  </p>
                  <p className="text-end">
                    <span className="badge rounded-pill bg-primary">
                      {songDetails?.song_type} Song
                    </span>{" "}
                    <span className="badge rounded-pill bg-primary">
                      {songDetails?.language}
                    </span>{" "}
                    {
                      albumDetails.relInBangladesh ? <span className="badge rounded-pill bg-success">
                      Relesed In Bangladesh
                    </span> : null
                    }
                  </p>
                </div>
              </div>
              <div className="lower d-flex justify-content-center gap-2">
                
                <ul style={{ width: "50%", margin: 0, padding: 0 }}>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Genre </h6>
                    <h6> {songDetails?.gener} </h6>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Sub Genre </h6>
                    <h6> {songDetails?.sub_gener} </h6>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Mood </h6>
                    <h6> {songDetails?.mood}</h6>
                  </li>
                 {songDetails?.director && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Director </h6>
                    <h6> {songDetails?.director}</h6>
                  </li>}
                  {
                    songDetails.isrc && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">ISRC </h6>
                    <h6> {songDetails?.isrc}</h6>
                  </li>
                  }
                  {
                    songDetails.trackDuration && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Track Duration </h6>
                    <h6> {songDetails?.trackDuration}</h6>
                  </li>
                  }
                </ul>
                <ul style={{ width: "50%", margin: 0, padding: 0 }}>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">CRBT Start</h6>
                    <h6>{songDetails?.crbt_start}</h6>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Language</h6>
                    <h6>{songDetails?.language}</h6>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Song Type</h6>
                    <h6>{songDetails?.song_type}</h6>
                  </li>
                  {songDetails?.star_cast && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Star Cast</h6>
                    <h6>{songDetails?.star_cast}</h6>
                  </li>}
                  {
                    albumDetails.upc && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">UPC </h6>
                    <h6> {albumDetails?.upc}</h6>
                  </li>
                  }
                  {
                    albumDetails.catalogNo && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Catalogue Number </h6>
                    <h6> {albumDetails?.catalogNo}</h6>
                  </li>
                  }
                </ul>
              </div>
              

                {
                    albumDetails.label && <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold">Label Name </h6>
                    <h6> {albumDetails?.label}</h6>
                  </li>
                  }
              

                  {
                    songDetails?.song_desc && <h6 className="my-2">
                    <b>
                      <MdDescription />{" "}
                    </b>{" "}
                    {songDetails?.song_desc}
                  </h6>
                  }
              {
                albumDetails.othersInfo && <div className="alert alert-warning my-2" role="alert" style={{fontSize:'17px'}}>
                <p>{albumDetails.othersInfo.split('http')[0]} <br /><br /> {linkConverter(albumDetails.othersInfo).map((url,key)=>{
                  return(<div key={key+3424}>
                    <a href={url} >{url}</a> <br />
                  </div>)
                })}</p>
              </div>
              }
              

            </div>
    </div>
  )

  return (
    <div>
       <ToastContainer/>

      <Modal
      theme={classes.palette}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          
            {body}
         
        </Fade>

      </Modal>
    </div>
  );
}
