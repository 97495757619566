import { React, useEffect, useState, useRef } from "react";
import "./AdminHome.css";
import { MdOutlineWhatshot, MdCancel, MdOutlineLiveTv, MdFileUpload } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoAddCircle } from "react-icons/io5";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadDocumentsTable from "./UploadDocumentsTable";
import UploadDocModal from "./UploadDocModal";
import CreatePost from "./CreatePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from 'xlsx'
import { ADMIN_USER } from "../../utils/role";

function AdminHome() {
  const [counter, setCounter] = useState("");
  const [underVarify, SetUnderVarify] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const hiddenFileInput = useRef(null)
  let role = localStorage.getItem("role")
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/countAllAlbum`,
    })
      .then((res) => {
        setCounter(res?.data);

      })
      .catch((err) => {
        console.log(err);
        toast.error("Somthing Went Wrong")
      });
    getUnderVaify();
  }, []);

  const getUnderVaify = () => {
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getUnderVerificationAllAlbum`,
      method: 'GET'
    })
      .then((res) => {

        SetUnderVarify(res.data?.album);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Somthing Went Wrong")
      });
  };


  const uploadFaqHandler = () => {
    hiddenFileInput?.current?.click()
  }

  const readFAQFile = file => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader(file)
      if (!fileReader) {
        return
      }
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = e => {
        const buffer = e.target.result
        const wb = XLSX.read(buffer, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws)
        resolve(data)
        fileReader.onerror = error => {
          reject(error)
        }
      }
    })
    promise.then(data => {
      async function updateBackend() {
        try {
          axios({
            url: `${process.env.REACT_APP_DOMAIN_KEY}/api/support/addFAQ`,
            method: 'POST',
            data: { data }
          })
            .then((res) => {
              toast.success("FAQ Uploaded")
            })
            .catch((err) => {
              console.log(err);
              toast.error("Somthing Went Wrong")
            });
        } catch (err) {
          console.log('error = ', err)
        }
      }

      updateBackend()
    })
  }



  return (
    <div className="container_main">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />
      {reloadTable && <div className="mid_loader">Loading...</div>}
      <div className="row">
        <Link
          to="/new_submission"
          className=" text-decoration-none col-xl-3 col-md-6 mb-4 "
        >
          <div className="card border-left-warning h-100 py-2 card-color">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    New Submission{" "}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {counter.pending}
                  </div>
                </div>
                <div className="col-auto text-warning card_icon">
                  <MdOutlineWhatshot />
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/approved"
          className="text-decoration-none col-xl-3 col-md-6 mb-4 "
        >
          <div className="card border-left-success h-100 py-2 card-color">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Approved{" "}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {counter.success}
                  </div>
                </div>
                <div className="col-auto text-success card_icon">
                  <BsCheckCircleFill />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/release"
          className="text-decoration-none col-xl-3 col-md-6 mb-4 "
        >
          <div className="card border-left-primary h-100 py-2 card-color">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Released{" "}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {counter.released}
                  </div>
                </div>
                <div className="col-auto text-primary card_icon">
                  <MdOutlineLiveTv />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/cancel"
          className="text-decoration-none col-xl-3 col-md-6 mb-4 "
        >
          <div className="card border-left-danger h-100 py-2 card-color">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                    Cancel{" "}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {counter.cancle}
                  </div>
                </div>
                <div className="col-auto text-danger card_icon">
                  <MdCancel />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      {/* ----------------UPPER CARDS----------- */}

      <div className="tool_box ">
        <div className="upload_box">
          <div className="upper">
            <span>
              {" "}
              Uploaded Documents{" "}
              {/* <BiRefresh
                fontSize={30}
                onClick={refreshTable}
                style={{ cursor: "pointer" }}
              />{" "} */}
            </span>
            {role !== ADMIN_USER && <Button
              variant="contained"
              style={{
                backgroundColor: "#1cc88a",
                color: "black",
              }}
              disabled={role === ADMIN_USER}
              onClick={() => {
                setActiveModal(true);
              }}
            >
              Upload
            </Button>}

          </div>
          <UploadDocumentsTable
            reloadTable={reloadTable}
            setReloadTable={setReloadTable}
            role={role}
          />
        </div>

        <div className="verify_box">
          <div className="upper">Under Verification</div>

          <div className="item_list">
            {underVarify.map((val, i) => {
              return (
                <div key={i} className="verify_item">
                  <small className="text-xs font-weight-bold text-warning text-uppercase">
                    {val.user_id.userName}
                  </small>
                  {val.title}
                  <div className="icon">
                    <CircularProgress
                      style={{
                        color: "#ffc400",
                        fontSize: "0.4rem",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {activeModal && (
        <UploadDocModal
          closeModal={setActiveModal}
          setReloadTable={setReloadTable}
        />
      )}

      {/* CREATE POST */}
      
      <div
        className="create_post text-primary"
      >
        <Button variant="contained" disabled={role === ADMIN_USER} startIcon={<IoAddCircle />} sx={styles.primaryBtn} onClick={() => {
          setShowPostModal(true);
        }}>
          Create Post
        </Button>
        <Button variant="contained" disabled={role === ADMIN_USER} startIcon={<MdFileUpload />} sx={styles.primaryBtn} onClick={uploadFaqHandler}>
          Upload FAQ
        </Button>
      </div>

      {/* hidden input for faq upload */}

      <input
        hidden
        type='file'
        ref={hiddenFileInput}
        disabled={role === ADMIN_USER}
        onChange={e => {
          const file = e.target.files[0]
          if (file && file.name.endsWith('.xlsx')) {
            // Process the XLSX file
            readFAQFile(file);
          } else {
            // Handle invalid file type
            toast.error('Please select an XLSX file.');
          }
          e.target.value = null;
        }}
      />

      {showPostModal && <CreatePost closeModal={setShowPostModal} />}
    </div>
  );
}


const styles = {
  primaryBtn: {
    backgroundColor: "#1cc88a",
    color: "black",
  }
}

export default AdminHome;
