import { React, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { ADMIN_USER } from './utils/role';

function AdminUserProtected(props) {
  const { Component } = props
  const nav = useNavigate();
  useEffect(() => {
    let adminToken = localStorage.getItem("admin_token_")
    let role = localStorage.getItem("role")
    if (!adminToken || role === ADMIN_USER) {
      nav("/");
    }
  }, [])



  return (
    <div>
      <Component />
    </div>
  )
}

export default AdminUserProtected