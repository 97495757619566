import { React, useState, useEffect, useRef } from "react";
import { MdDownload } from "react-icons/md";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AiFillEdit } from "react-icons/ai";
import { useFormik } from "formik";
import MatModal from "./MatModal.jsx";
import AlbumEditModal from "./AlbumEditModal.jsx";
import ActionRequired from "./ActionRequired.jsx";

function SubmissionDetails({ albumId , albumDetails, setShowDetails, refreshTable }) {
  const [song, setSong] = useState([]); 
  const [albumArtId, setAlbumArtId] = useState("");
  const [load, setLoad] = useState(false);
  const [slectedsongForEdit, setSelectedSongForEdit] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editAlbum, setEditAlbum] = useState(false)
  const [action,setAction] = useState(false)
  const [reportId,setReportId] = useState({})
  const [togglebtn,setTogglebtn] = useState(true)
  const [togglebtnForCancel,setTogglebtnForCancel] = useState(true)


  const showModal = useRef(null);


  useEffect(() => {
    getSongDataApi()
  }, []);


  // api call to get song data
  const getSongDataApi=()=>{
    const data = {
      albumId: albumId,
    };
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllSongs`,
      method: "post",
      data: data,
    }).then((resp) => {
      setSong(resp.data.data);
      setAlbumArtId(resp.data?.data[0]?.albumId?.album_art_id);
    });
  }



  // universal download method
  const downloadFile = (data, type) => {

    if(type === "album_art"){
      axios({
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getSongDetais/${data}`,
        method: "get"
      }).then((res)=>{
        window.open(res.data.url)
      }).catch((err)=>{
        toast.error("Somthing Went Wrong")
      })
    }else{

      window.open(data);

    }
  };



  // handle approve button 
  const handleApprove = () =>{
    const postData ={
      albumId : albumId,
      status : "SUCCESS",
      message : "👍 Your album has been approved"
    }
    axios({
      url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateAlbumStatus`,
      method: "post",
      data : postData
    }).then((res)=>{
  
        toast.success("Approuved Successfully")
        refreshTable()
        setTimeout(()=>{
          setTogglebtn(false)
        },1000)
        setShowDetails(true)
    }).catch((err)=>{
      console.log("error==",err)
      toast("Somthing Went Wrong...")
    })
  }



   // handle cancel button 
  const handleCancel = () =>{
    const postData ={
      albumId : albumId,
      status : "CANCEL",
      message : "Your Album has been cancelled"
    }
    axios({
      url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateAlbumStatus`,
      method: "post",
      data : postData
    }).then((res)=>{

      toast.success("Cancel Successfully")
      
        setTogglebtnForCancel(false)
        refreshTable()
        setTimeout(()=>{
          setTogglebtn(false)
        },1000)
        setShowDetails(true)
    }).catch((err)=>{
      console.log("error==",err)
      toast("Somthing Went Wrong...")
    })
  }



  const formik = useFormik({
    initialValues: {
      song_title: "",
      song_type: "",
      language: "",
      song_desc: "",
      gener: "",
      sub_gener: "",
      mood: "",
      singer: "",
      composer: "",
      director: "",
      star_cast: "",
      explicit: "",
      lyricist: "",
      wav_file: "",
      noc_doc: "",
      upload_mp3: "",
      crbt_start: "",
      crbt_name: "",
    },
    onSubmit: (value) => {

    },
  });

  

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />

      {load && (
        <div className="mid_loader">
          <CircularProgress />
        </div>
      )}
      <div className="sub_details_main">

        <div className="left">
          {song.map((val, i) => {
            return (
              <div key={i}>
                <button
                  ref={showModal}
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  hidden
                >
                  Launch demo modal
                </button>
                <div className="edit_btn_div ">
                  <button
                    onClick={() => {
                      showModal.current.click();
                      setSelectedSongForEdit(val);
                      setShowEditModal(true);
                    }}
                    type="button"
                  >
                    <AiFillEdit />{" "}
                  </button>
                </div>

                <div id={val._id} className="song my-3" key={i}>
                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Song Title
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.song_title}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>

               


                 

          
                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Language
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.language}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Genre
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.gener}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Sub Genre
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.sub_gener}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Mood
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.mood}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        type="text"
                        row="10"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.song_desc}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Singer
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.singer}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Composer
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.composer}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Director
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.director}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Star Cast
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.star_cast}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="explicit"
                      className="col-sm-2 col-form-label"
                    >
                      Explicit
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.explicit}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>
                  
                  
                  <div className="form-group row">
                    <label
                      htmlFor="song_type"
                      className="col-sm-2 col-form-label"
                    >
                      Song Type
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="song_type"
                        name="song_type"
                        value={val.song_type}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Lyricist
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.lyricist}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-3 col-form-label">
                      CRBT Start Time
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.crbt_start}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      CRBT name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.crbt_name}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-10">
                   
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          downloadFile(val.wav_file_url, "wav");
                        }}
                      >
                        <MdDownload /> Wav
                      </button>
                      <button
                        type="button"
                        className="btn btn-success ml-2"
                        onClick={() => {
                          downloadFile(val?.albumId?._id, "album_art");
                        }}
                      >
                        <MdDownload /> Album Art
                      </button>
                      {
                      val.hasOwnProperty('noc_doc_url') &&
                      <button
                        type="button"
                        className="btn btn-info ml-2"
                        onClick={() => {
                          downloadFile(val?.noc_doc_url, "pdf");
                        }}
                      >
                        <MdDownload /> NOC
                      </button>
                      }
                       
                       
                      {
                      val.hasOwnProperty('upload_mp3_url') &&
                      <button
                        type="button"
                        className="btn btn-info ml-2"
                        onClick={() => {
                          downloadFile(val?.upload_mp3_url, "mp3");
                        }}
                      >
                        <MdDownload /> Mp3
                      </button>
                    }
                    

                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          setReportId({songId : val?._id, form : "song"})
                          setAction(true)
                          
                        }}
                      >
                         Action Required
                      </button>

                    </div>
                  </div>

                  <br />
                  <hr />
                </div>
                <br />
              </div>
            );
          })}

          {/* ===end left=== */}
        </div>
        <div className="right">
          {song.map((val, i) => {
            return (
             
                <div key={i} className="song_details_list">
                  <a href={`#${val._id}`}>{val.song_title}</a>
                </div>
             
            );
          })}

          <hr />

          {/* <div className="form-group ">
            <textarea
              className="myTextarea"
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></textarea>
            <button type="button" className="btn btn-primary float-right mt-3">
              Action Required
            </button> 
          </div> */}

                      <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          setReportId({albumId : albumDetails?._id, form : "album"})
                          setAction(true)
                         
                        }}
                      >
                         Action Required
                      </button>

          <div className="button-group">
            
             <button type="button" onClick={()=>{
             setEditAlbum(true)
  
            }} className="btn btn-warning" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Album">
              Edit
            </button>
            {
              togglebtnForCancel &&
            <button type="button" onClick={handleCancel} className="btn btn-danger ml-2">
              Cancel
            </button>
            }
            { togglebtn && <button type="button" onClick={handleApprove} className="btn btn-success ml-2">
              Approve
            </button>}
          </div>
        </div>
      </div>
      {
        editAlbum && (
          <AlbumEditModal closeModal={setEditAlbum} album={albumDetails} getSongData={getSongDataApi}/>
        )
      }

      {showEditModal && (
        <MatModal closeModal={setShowEditModal} songData={slectedsongForEdit} getSongData={getSongDataApi}/>
      )}

      {
        action && (
          <ActionRequired closeModal={setAction} data={reportId}  setShowDetails={setShowDetails} refreshTable={refreshTable} />
        )
      }
    </>
  );
}

export default SubmissionDetails;
