import { React, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

function AdminProtected(props) {
  const { Component } = props
  const nav = useNavigate();
  useEffect(() => {
    let adminToken = localStorage.getItem("admin_token_")
    let role = localStorage.getItem("role")
    if (!adminToken && (role !== "ADMIN" || role !== "ADMIN_USER")) {
      nav("/");
    }
  }, [])



  return (
    <div>
      <Component />
      {/* {props.childreen} */}
    </div>
  )
}

export default AdminProtected