import React from "react";
import { useFormik } from "formik";
import { AiFillCheckCircle } from "react-icons/ai";
import * as yup from "yup";
import { useState } from "react";
import "./Upload.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

function UploadForm() {
  const nav = useNavigate();

  const [content, setContent] = useState();
  const [meta, setMeta] = useState([]);
  const [final, setFinal] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [active, setActive] = useState(false);
  const [load, setLoad] = useState(false);
  const [btnDisabled, setBtnDisabled]=useState(false)
  const [progress, setProgress]=useState(null)

  const scrollTop=()=>{
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }) 
  }

  const validationMeta = yup.object({
    title: yup.string().required("Required Feild"),
    content_type: yup.string().required("Required Feild"),
    album_art: yup.mixed().required("Album Art Is Mandetory Feild")
    .test("type", "Only the JPEG format with 3000 x 3000 pixel is accepted", (value) => {
      return value && (
          value.type === "image/jpeg" ||
          value.type === "image/jpg"
      );
    })
    
  });

  const validationFinal = yup.object({
    song_title: yup.string().required("Required Feild"),
    song_type: yup.string().required("Required Feild"),
    language: yup.string().required("Required Feild"),
    gener: yup.string().required("Required Feild"),
    sub_gener: yup.string().required("Required Feild"),
    mood: yup.string().required("Required Feild"),
    singer: yup.string().required("Required Feild"),
    composer: yup.string().required("Required Feild"),
    lyricist: yup.string().required("Required Feild"),
    wav_file: yup.mixed()
    .required("Wav File Is Mandetory Feild")
    .test("type", "Only .wav file format is accepted", (value) => {
      return value && (
          value.type === "audio/wav"
      );
    }),
    noc_doc : yup.mixed()
    .test("type", "Only the following formats are accepted: .pdf, .doc and .jpeg,", (value) => {

      return value && (
          value.type === "image/jpeg" ||
          value.type === "application/pdf" ||
          value.type === "application/doc" ||
          value.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
 
      );
    }),
    crbt_name: yup.string().required("Required Feild"),
    crbt_start: yup.string().required("Required Feild").test("type","Follow The Specified Formate (HH:MM:SS)",(value)=>{
      let format =  /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
      return value && (
        value.match(format)
      );
    }),
  });

  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };

  let next = toggle ? "active show" : null;
  let prev = !toggle ? "active show" : null;

  const metaFormik = useFormik({
    initialValues: {
      title: "",
      release_date: "",
      live_date: "",
      film_banner: "",
      film_producer: "",
      album_art: "",
      content_type: "",
    },
    validationSchema: validationMeta,
    onSubmit: (values) => {
      setMeta(values);
      handleToggle();
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      song_title: "",
      song_type: "",
      language: "",
      song_desc: "",
      gener: "",
      sub_gener: "",
      mood: "",
      singer: "",
      composer: "",
      director: "",
      star_cast: "",
      explicit: "no",
      lyricist: "",
      wav_file: "",
      noc_doc: "",
      crbt_start: "",
      crbt_name: meta.title !== "" ? meta.title : "",
    },
    validationSchema: validationFinal,
    onSubmit: (value) => {
      
      setFinal((prevData) => [...prevData, value]);
      //have to enable final sumbit
      setActive(true)
      formik.resetForm();
      toast.success("Saved");
    },
  });

  function UploadSong(albumId) {
    
    final.forEach((song) => {
      var finalFormData = new FormData();
      finalFormData.append("song_title", song?.song_title);
      finalFormData.append("song_type", song?.song_type);
      finalFormData.append("language", song?.language);
      finalFormData.append("song_desc", song?.song_desc);
      finalFormData.append("gener", song?.gener);
      finalFormData.append("sub_gener", song?.sub_gener);
      finalFormData.append("mood", song?.mood);
      finalFormData.append("singer", song?.singer);
      finalFormData.append("composer", song?.composer);
      finalFormData.append("director", song?.director);
      finalFormData.append("star_cast", song?.star_cast);
      finalFormData.append("explicit", song?.explicit);
      finalFormData.append("lyricist", song?.lyricist);
      finalFormData.append("wav_file", song?.wav_file);
      finalFormData.append("noc_doc", song?.noc_doc);
      finalFormData.append("crbt_start", song?.crbt_start);
      finalFormData.append("crbt_name", song?.crbt_name);
      finalFormData.append("albumId", albumId);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/addDoc`,
        data: finalFormData,
        onUploadProgress : (data)=>{
          setProgress(Math.round((data.loaded / data.total)*100))
        }
      })
        .then(function (response) {
          //handle success
          setLoad(false)
          
          if (response.status === 200) {
            nav("/dashboard");
            toast.success(
              "Form has been submitted for " +
                response.data.songDetails.song_title
            );
          }else{
            toast("Somthing went wrong, please try again");
          }
        })
        .catch(function (error) {
          //handle error
          setLoad(false)
          
          toast("Somthing went wrong, please try again");
        });
    }); 
    
  }

  const handleFinalSubmit = async() => {
    if(active){
    setLoad(true)
    setBtnDisabled(true)
    var metaFormData = new FormData();
    metaFormData.append("title", meta?.title);
    metaFormData.append("release_date", meta?.release_date);
    metaFormData.append("live_date", meta?.live_date);
    metaFormData.append("film_banner", meta?.film_banner);
    metaFormData.append("film_producer", meta?.film_producer);
    metaFormData.append("album_art", meta?.album_art);
    metaFormData.append("content_type", meta?.content_type);

    const token = localStorage.getItem("user_token");
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/createAlbum`,
      data: metaFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        //handle success
        console.log(response)
        if (response.status === 200) {
          let albumIdResp = response?.data?.albumId;
          UploadSong(albumIdResp);
        }
      })
      .catch(function (error) {
        //handle error
        console.log("ERROR === ", error);
        toast.error("Something Went Wrong")
      });
    }else{
         toast("Save Your Song");
    } 
  }

  return (
    <div>
    {load && (
        <div className="mid_loader_user text-light flex-column">
          <CircularProgress /> 
          <div className="progress mb-3 mt-2" style={{width: '300px'}}>
            <div className="progress-bar" role="progressbar" style={{width:`${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
          </div>
          
          <p>Please Wait While Uploading Your Documents</p>
          <small>Don't close the window</small>
        </div>
      )}
      <nav>
        <div
          className="nav nav-tabs d-flex justify-content-center"
          id="nav-tab"
          role="tablist"
        >
          <button
            className={`nav-link ${next ? "active" : null}`}
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            disabled
          >
            Step One
          </button>
          <button
            className={`nav-link ${prev ? "active" : null}`}
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            disabled
          >
            Step Two
          </button>
        </div>
      </nav>
      <form action="">
        <div className="tab-content" id="nav-tabContent">
          <div
            className={`tab-pane fade ${next}`}
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="form_box">
              <div className="form_container shadow">
                <div className="form-group row">
                  {metaFormik.errors.title && metaFormik.touched.title ? (
                    <div style={{ color: "red" }}>
                      {metaFormik.errors.title}
                    </div>
                  ) : null}
                  <label htmlFor="title" className="col-sm-4 col-form-label">
                    Title <span className="red">*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      required
                      value={metaFormik.values.title}
                      onChange={metaFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="release_date"
                    className="col-sm-4 col-form-label"
                  >
                    Release Date
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      id="release_date"
                      name="release_date"
                      value={metaFormik.values.release_date}
                      onChange={metaFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="live_date"
                    className="col-sm-4 col-form-label"
                  >
                    Live Date
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      id="live_date"
                      name="live_date"
                      value={metaFormik.values.live_date}
                      onChange={metaFormik.handleChange}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  {metaFormik.errors.content_type &&
                  metaFormik.touched.content_type ? (
                    <div style={{ color: "red" }}>
                      {metaFormik.errors.content_type}
                    </div>
                  ) : null}
                  <label
                    htmlFor="content_type"
                    className="col-sm-4 col-form-label"
                  >
                    Content Type <span className="red">*</span>
                  </label>

                  <div className="custom-dropdown-control col-lg-8">
                    <select
                      id="content_type"
                      name="content_type"
                      className="custom-select"
                      onClick={(e) => {
                        setContent(e.target.value);
                      }}
                      onChange={metaFormik.handleChange}
                      value={metaFormik.values.content_type}
                      required
                    >
                      <option value={""}>--Select One--</option>
                      <option value="album">Album</option>
                      <option value="film">Film</option>
                    </select>
                  </div>
                </div>
                {content === "film" ? (
                  <>
                    <div className="form-group row">
                      <label
                        htmlFor="film_banner"
                        className="col-sm-4 col-form-label"
                      >
                        Film Banner
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="film_banner"
                          name="film_banner"
                          onChange={metaFormik.handleChange}
                          value={metaFormik.values.film_banner}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="film_producer"
                        className="col-sm-4 col-form-label"
                      >
                        Film Producer
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="film_producer"
                          name="film_producer"
                          onChange={metaFormik.handleChange}
                          value={metaFormik.values.film_producer}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="form-group row">
                {metaFormik.errors.album_art &&
                  metaFormik.touched.album_art ? (
                    <div style={{ color: "red" }}>
                      {metaFormik.errors.album_art}
                    </div>
                  ) : null}
                  <label
                    htmlFor="album_art"
                    className="col-sm-4 col-form-label"
                  >
                    Upload Album Art<span className="red"> *</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control btn "
                      type="file"
                      id="album_art"
                      name="album_art"
                      onChange={(e) => {
                        metaFormik.setFieldValue(
                          "album_art",
                          e.target.files[0]
                        );
                      }}
                    />
                  </div>
                </div>

                <div className=" d-flex justify-content-end ">
                  <button
                    type="button"
                    id="next_btn"
                    className="btn btn-primary col-lg-3"
                    onClick={(e) => {
                      e.preventDefault();
                      metaFormik.handleSubmit();
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${prev}`}
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <button
              type="button"
              className="btn btn-warning mt-3"
              onClick={handleToggle}
            >
              Prev
            </button>
            <div className="form_box">
              <div className="form_container shadow">
                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" d-flex flex-column col-lg-5">
                    {formik.errors.song_title &&
                    formik.touched.song_title ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.song_title}
                      </div>
                    ) : null}
                    <label htmlFor="song_title">
                      {" "}
                      Song Title <span className="red">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="song_title"
                      name="song_title"
                      onChange={formik.handleChange}
                      value={formik.values.song_title}
                      required
                    />
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.song_type &&
                    formik.touched.song_type ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.song_type}
                      </div>
                    ) : null}
                    <label htmlFor="song_type">
                      <span className="red">*</span> Song Type
                    </label>
                    <select
                      id="song_type"
                      name="song_type"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.song_type}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Original">Original</option>
                      <option value="Cover">Cover</option>
                      <option value="Remix">Remix</option>
                      <option value="Medley">Medley</option>
                      <option value="Live">Live</option>
                      <option value="Karaoke">Karaoke</option>
                      <option value="DJ">DJ</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.language &&
                    formik.touched.language ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.language}
                      </div>
                    ) : null}
                    <label htmlFor="language">
                      Language <span className="red">*</span>{" "}
                    </label>
                    <select
                      id="language"
                      name="language"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.language}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Adibasi">Adibasi</option>
                            <option value="Awadhi">Awadhi</option>
                            <option value="Assamese">Assamese</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Banjara">Banjara</option>
                            <option value="Bhojpuri">Bhojpuri</option>
                            <option value="Chhattisgarhi">Chhattisgarhi</option>
                            <option value="Dogri">Dogri</option>
                            <option value="English">English</option>
                            <option value="Garhwali">Garhwali</option>
                            <option value="Gujarati">Gujarati</option>
                            <option value="Haryanvi">Haryanvi</option>
                            <option value="Hindi">Hindi</option>
                            <option value="Himachali">Himachali</option>
                            <option value="Instrumental">Instrumental</option>
                            <option value="Japanese">Japanese</option>
                            <option value="Kannada">Kannada</option>
                            <option value="Kashmiri">Kashmiri</option>
                            <option value="Khasi">Khasi</option>
                            <option value="Kokborok">Kokborok</option>
                            <option value="Konkani">Konkani</option>
                            <option value="Kumauni">Kumauni</option>
                            <option value="Malayalam">Malayalam</option>
                            <option value="Mandarin">Mandarin</option>
                            <option value="Marathi">Marathi</option>
                            <option value="Marwari">Marwari</option>
                            <option value="Maithili">Maithili</option>
                            <option value="Manipuri">Manipuri</option>
                            <option value="Naga">Naga</option>
                            <option value="Nagpuri">Nagpuri</option>
                            <option value="Nepali">Nepali</option>
                            <option value="Odia">Odia</option>
                            <option value="Oriya">Oriya</option>
                            <option value="Pali">Pali</option>
                            <option value="Persian">Persian</option>
                            <option value="Punjabi">Punjabi</option>
                            <option value="Rajasthani">Rajasthani</option>
                            <option value="Sainthili">Sainthili</option>
                            <option value="Sambalpuri">Sambalpuri</option>
                            <option value="Sanskrit">Sanskrit</option>
                            <option value="Santali">Santali</option>
                            <option value="Sinhala">Sinhala</option>
                            <option value="Sindhi">Sindhi</option>
                            <option value="Tamil">Tamil</option>
                            <option value="Telegu">Telegu</option>
                            <option value="Tibetan">Tibetan</option>
                            <option value="Turkish">Turkish</option>
                            <option value="Urdu">Urdu</option>
                                  </select>
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.gener &&
                    formik.touched.gener ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.gener}
                      </div>
                    ) : null}
                    <label htmlFor="gener">
                      Genre <span className="red">*</span>{" "}
                    </label>
                    <select
                      id="gener"
                      name="gener"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.gener}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Film">Film</option>
                      <option value="Devotional">Devotional</option>
                      <option value="Traditional">Traditional</option>
                      <option value="Indie Pop">Indie Pop</option>
                      <option value="Instrumental">Instrumental</option>
                      <option value="Western Classical">
                        Western Classical
                      </option>
                      <option value="Carnatic Classical">
                        Carnatic Classical
                      </option>
                      <option value="Hindustani Classical">
                        Hindustani Classical
                      </option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="English Pop">English Pop</option>
                      <option value="Ghazal">Ghazal</option>
                      <option value="Regional Pop">Regional Pop</option>
                      <option value="Lounge">Lounge</option>
                      <option value="Fusion">Fusion</option>
                      <option value="Folk">Folk</option>
                      <option value="Electronic">Electronic</option>
                      <option value="HipHop">HipHop</option>
                      <option value="Rap">Rap</option>
                      <option value="Rock">Rock</option>
                      <option value="Blues">Blues</option>
                      <option value="Metal">Metal</option>
                      <option value="Jazz">Jazz</option>
                    </select> 
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.sub_gener &&
                    formik.touched.sub_gener ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.sub_gener}
                      </div>
                    ) : null}
                    <label htmlFor="sub_gener">
                      Sub Genre <span className="red">*</span>{" "}
                    </label>
                    <select
                      id="sub_gener"
                      name="sub_gener"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.sub_gener}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Acoustic">Acoustic</option>
<option value="Alternative">Alternative</option>
<option value="Baul Geet">Baul Geet</option>
<option value="Bhajan">Bhajan</option>
<option value="Bihu">Bihu</option>
<option value="Borgeet">Borgeet</option>
<option value="Chalisa">Chalisa</option>
<option value="Chhattisgarhi">Chhattisgarhi</option>
<option value="Contemporary">Contemporary</option>
<option value="DJ Mix">DJ Mix</option>
<option value="Dogri">Dogri</option>
<option value="Dogri">Dogri</option>
<option value="Experimental">Experimental</option>
<option value="Geet">Geet</option>
<option value="Geeti">Geeti</option>
<option value="Instrumental">Instrumental</option>
<option value="Indie Folk">Indie Folk</option>
<option value="Kashmiri">Kashmiri</option>
<option value="Karwa Chaut Songs">Karwa Chaut Songs</option>
<option value="Khasi">Khasi</option>
<option value="Kirtan">Kirtan</option>
<option value="Kokborok">Kokborok</option>
<option value="Kumauni">Kumauni</option>
<option value="Langa Geet">Langa Geet</option>
<option value="LoFi">LoFi</option>
<option value="Mandarin">Mandarin</option>
<option value="Mapilla">Mapilla</option>
<option value="Mantra">Mantra</option>
<option value="Marriage Song">Marriage Song</option>
<option value="Marwari">Marwari</option>
<option value="Nazrul Geeti">Nazrul Geeti</option>
<option value="Naat">Naat</option>
<option value="Naga">Naga</option>
<option value="Nepali">Nepali</option>
<option value="Persian">Persian</option>
<option value="Pali">Pali</option>
<option value="Progressive">Progressive</option>
<option value="Psychedelic">Psychedelic</option>
<option value="Qawwals">Qawwals</option>
<option value="Rabindra Sangeet">Rabindra Sangeet</option>
<option value="Rajanikanta">Rajanikanta</option>
<option value="Raksha Bandhan">Raksha Bandhan</option>
<option value="Soundtrack">Soundtrack</option>
<option value="Sinhala">Sinhala</option>
<option value="Sindhi">Sindhi</option>
<option value="Traditional">Traditional</option>
<option value="Tibetan">Tibetan</option>
<option value="Turkish">Turkish</option>
                    </select>
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.mood &&
                    formik.touched.mood ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.mood}
                      </div>
                    ) : null}
                    <label htmlFor="mood">
                      <span className="red">*</span>Mood
                    </label>
                    <select
                      id="mood"
                      name="mood"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.mood}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Romantic">Romantic</option>
                      <option value="Happy">Happy</option>
                      <option value="Sad">Sad</option>
                      <option value="Dance">Dance</option>
                      <option value="Bhangra">Bhangra</option>
                      <option value="Patriotic">Patriotic</option>
                      <option value="Nostalgic">Nostalgic</option>
                      <option value="Inspirational">Inspirational</option>
                      <option value="Enthusiastic">Enthusiastic</option>
                      <option value="Optimistic">Optimistic</option>
                      <option value="Passion">Passion</option>
                      <option value="Pessimistic">Pessimistic</option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="Peppy">Peppy</option>
                      <option value="Philosophical">Philosophical</option>
                      <option value="Mellow">Mellow</option>
                      <option value="Calm">Calm</option>
                    </select>
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-12">
                    <label htmlFor="song_desc"> Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Write About The Subject Of Your Song"
                      id="song_desc"
                      name="song_desc"
                      onChange={formik.handleChange}
                      value={formik.values.song_desc}
                    ></textarea>
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.singer &&
                    formik.touched.singer ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.singer}
                      </div>
                    ) : null}
                    <label htmlFor="singer">
                      Singer <span className="red">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="singer"
                      name="singer"
                      onChange={formik.handleChange}
                      value={formik.values.singer}
                      required
                    />
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
              
                  {formik.errors.composer &&
                    formik.touched.composer ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.composer}
                      </div>
                    ) : null}
                    <label htmlFor="composer">
                      <span className="red">*</span> Composer
                    </label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="composer"
                      name="composer"
                      onChange={formik.handleChange}
                      value={formik.values.composer}
                      required
                    />
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="director">Director</label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="director"
                      name="director"
                      onChange={formik.handleChange}
                      value={formik.values.director}
                    />
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="star_cast">Star Cast</label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="star_cast"
                      name="star_cast"
                      onChange={formik.handleChange}
                      value={formik.values.star_cast}
                    />
                  </div>
                </div>

                <div className=" d-flex justify-content-between align-items-center my-2">
                  <div className=" d-flex flex-column col-lg-5">
                    <label htmlFor="explicit">Explicit</label>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="explicit" 
                        id="explicit_yes"
                        onChange={formik.handleChange}
                        value="yes"
                        
                      />
                      <label
                        className="form-check-label"
                        htmlFor="explicit_yes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="explicit"
                        id="explicit_no"
                        onChange={formik.handleChange}
                        value="no"
                        
                      />
                      <label className="form-check-label" htmlFor="explicit_no">
                        No
                      </label>
                    </div>
                  </div>
                  <div className=" d-flex flex-column col-lg-5">
                  {formik.errors.lyricist &&
                    formik.touched.lyricist ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.lyricist}
                      </div>
                    ) : null}
                    <label htmlFor="lyricist"> Lyricist  <span className="red">*</span></label>
                    <input
                      type="text"
                      className="form-control col-lg-12"
                      id="lyricist"
                      name="lyricist"
                      onChange={formik.handleChange}
                      value={formik.values.lyricist}
                    />
                  </div>
                </div>

                <div className="crbt-box">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div className=" d-flex flex-column col-lg-5">
                    
                      <label htmlFor="crbt_start">CRBT Start Time <span className="red">*</span>{" "}</label>
                      <input
                        type="text"
                        placeholder="HH:MM:SS"
                        className="form-control col-lg-12"
                        id="crbt_start"
                        name="crbt_start"
                        value={formik.values.crbt_start}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.crbt_start &&
                    formik.touched.crbt_start ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.crbt_start}
                      </div>
                    ) : null}
                    </div>
                   
                    <div className=" d-flex flex-column col-lg-5">
                  
                      <label htmlFor="crbt_name"> CRBT Name <span className="red">*</span>{" "}</label>
                      <input
                        type="text"
                        className="form-control col-lg-12"
                        id="crbt_name"
                        name="crbt_name"
                    
                        value={formik.values.crbt_name}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.crbt_name &&
                    formik.touched.crbt_name ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.crbt_name}
                      </div>
                    ) : null}
                    </div>
                    
                  </div>
                </div>

                
                <div className=" d-flex justify-content-between align-items-center mt-4">
                  <label className="d-flex col-lg-4" htmlFor="wav_file">
                    Upload Wav File <span className="red">*</span>{" "}
                  </label>
                   
                  <input
                    className="form-control btn col-lg-8"
                    type="file"
                    id="wav_file"
                    name="wav_file"
                    onChange={(e) => {
                      formik.setFieldValue("wav_file", e.target.files[0]);
                    }}
                    required
                  />
                </div>
                <div className="text-end mt-1">
                {formik.errors.wav_file &&
                    formik.touched.wav_file ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.wav_file}
                      </div>
                    ) : null}
                </div>
                <hr/>
                <div className="text-center mb-4">
                  <small className="text-warning">NOC Upload Mandatory for Activate All Services</small>
                </div>
                {formik.errors.noc_doc &&
                    formik.touched.noc_doc ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.noc_doc}
                      </div>
                    ) : null}
                <div className=" d-flex justify-content-between align-items-center mt-2">
                  <label className="d-flex col-lg-4" htmlFor="noc_doc">
                    Upload NOC
                  </label>
                  <input
                    className="form-control btn col-lg-8"
                    type="file"
                    id="noc_doc"
                    name="noc_doc"
                    onChange={(e) => {
                      formik.setFieldValue("noc_doc", e.target.files[0]);
                    }}
                  />
                </div>
              
               
                <br />
                <hr />
                <div className=" d-flex justify-content-center align-items-center mt-2">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      scrollTop();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              { final.length!==0 && <div className="display-container shadow">
                <h5>Uploaded Songs</h5> <br />
                {/* submission status */}
                {final.map((data, index) => {
                  return (
                    
                      <div className="status-box" key={index}>
                        <h5>
                          {data.song_title} by {data.singer}
                        </h5>
                        <span>
                          <AiFillCheckCircle />
                        </span>
                      </div>
                    
                  );
                })}
                <div className=" d-flex justify-content-center align-items-center mt-5">
                  <button
                    type="button"
                    className={`btn btn-success ${btnDisabled ? "disabled" : null } `}
                    onClick={handleFinalSubmit}
                   
                  >
                    Final Submit
                  </button>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default UploadForm;
