import React from "react";
import "./NewSubmisson.css";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import axios from "axios";


function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,

  };
}



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px"
  },
}));

export default function ActionRequired({ closeModal, data, setShowDetails, refreshTable }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

 

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    closeModal(false);
  };


  const formik = useFormik({
    initialValues: {
      message : ""
    },
    onSubmit: (value) => {
      
      sendMessage(value);
      
      formik.resetForm();
      
    },
  });

  const sendMessage = (message) => {
   
    if(data.form === "song"){
      message.songId = data.songId
    }else{
      message.albumId = data.albumId
    }
    axios({
      url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/sendAlbumOrSongCurrectionNotification`,
      method : "post",
      data : message
    }).then((res)=>{
   
      
      setShowDetails(true)
      refreshTable()
      closeModal(false);
    }).catch((err)=>{
      console.log("ERROR",err)
      closeModal(false);
    })
  };







  const body = (
    <div style={modalStyle} className={classes.paper}>
      
      <div className="song my-3">

      
                <div className="form-group row">
                  <label
                    htmlFor="message"
                    className="col-form-label"
                  >
                    Message
                  </label>
                  <div className="col-sm-12">
                    <textarea
                    className="myTextarea"
                    placeholder="Leave Your Error Message"
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    ></textarea>
                    
                  </div>
                </div>

              
                
              </div>
              <hr />
              <div className=" text-center">
              
              <button
                  type="button"
                  className="btn btn-primary "
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <b>Send</b>
                </button>
               
              </div>
    </div>
  );

  return (
    <div>
     
      <Modal
      theme={classes.palette}
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        {body}
      </Modal>

    </div>
  );
}
