import React, { useEffect } from "react";
import logo from "../../asset/images/logo.png"
import "./Sidebar.css"
import {Link} from 'react-router-dom'
import {AiFillDashboard,AiOutlineAreaChart} from 'react-icons/ai'
import {MdFileUpload} from 'react-icons/md'
import {IoIosArrowDown} from 'react-icons/io'
import {IoLogoBuffer} from 'react-icons/io'
import {VscFeedback} from 'react-icons/vsc'
import axios from 'axios'
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

 






function Sidebar() {

  const [post,setPost]=useState("");

  useEffect(() => {
    
    getAdminPost()
    return () => {
      getAdminPost()
    }
  }, [])

  function getAdminPost(){
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getCurrentAdminPost`,
      method: "get",
    }).then((res)=>{
      setPost(res.data?.post)
    }).catch((err)=>{
      console.log(err)
      toast.error("Something Went Wrong")
    })
  }
  


  return (
    <div className="background">
     <ToastContainer/>
      <ul
        className="navbar-nav sidebar-body sidebar sidebar-dark accordion "
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/dashboard"
        >
          <div className="sidebar-brand-icon ">
            <img src={logo} alt="" style={{filter: "invert(1)",  width: "45px", height: "auto"}} />
          </div>
          <div className="sidebar-brand-text mx-3"><b>M-Tool</b></div>
        </Link>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item ">
                <Link className="nav-link d-flex justify-content-center p-1  bg-success" to="/upload_form">
                    <span className="text-light " style={{
                      fontSize: '18px',
                      

                    }}>
                    <MdFileUpload/> New Release
                    </span>
                </Link>
        </li>
        
        <hr className="sidebar-divider" />

        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <AiFillDashboard/> 
            <span> Overview</span>
          </Link> 
        </li>


       

        <li className="nav-item">
                <a className="nav-link collapsed d-flex justify-content-between" href="/" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <div>
                    <IoLogoBuffer/>
                    <span> Catalogue</span>
                    </div>
                    <IoIosArrowDown id="arrowKey" />
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <Link className="collapse-item" to="/song_list">All Releases</Link>
                        <Link className="collapse-item" to="/action">Correction Required</Link>
                    </div>
                </div>
            </li>



             

        {/* <!-- Nav Item - Charts --> */}



            {/* <!-- Nav Item - Tables --> */}
            <li className="nav-item">
                <a className="nav-link collapsed d-flex justify-content-between" href="/" data-toggle="collapse" data-target="#collapseThree"
                    aria-expanded="true" aria-controls="collapseThree">
                    <div>
                    <AiOutlineAreaChart/>
                    <span> Analytics & Reports</span>
                    </div>
                    <IoIosArrowDown id="arrowKey"/>
                </a>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <Link className="collapse-item" to="/streaming_report">Streaming Analytics</Link>
                        <Link className="collapse-item" to="/revenue_report">Revenue Analytics</Link>
                        <Link className="collapse-item" to="/download_report">Download Reports</Link>
                    </div>
                </div>
            </li>


            <li className="nav-item">
                <Link className="nav-link" to="/support">
                    <VscFeedback/>
                    <span> Support</span></Link>
            </li>

        <hr className="sidebar-divider d-none d-md-block" />
        <br /> <br />
        {/* <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div> */}

         <div className="sidebar-card d-none d-lg-flex">
          <img src={post?.url} alt="" style={{
            width: '100%',
            height: 'auto',
            marginBottom: '8px',
            borderRadius: '5px'
          }} />
          <p className="text-center mb-2">
          {post?.caption}
          </p>
          <a
            className="btn btn-success btn-sm"
            target="_blanck"
            href={post?.link}
          >
            Click Here
          </a>
        </div>
      </ul>
     
    </div>
  );
}

export default Sidebar;
