import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import './Action.css'
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress";



function Action() {

  const [list, setList] = useState([]);
  const [load, setLoad]=useState(false);



  const navigate = useNavigate();
  const location = useLocation({state : {
    message : "",
    status : "" 
  }});

  useEffect(()=>{
    const userId = localStorage.getItem("userId")
    getAllNotifications(userId)

    if(location.state !== null){
      toast.success(location.state.message)
    }
  }, [])


  const  getAllNotifications = (userId)=>{
    const data = {
      userId : userId
    }
    setLoad(true)
    axios({
      url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAlbumSongCurrectionNotification`,
      method : "post",
      data : data
    }).then((res)=>{
      if(res.status===200){
        setLoad(false)
        setList(res.data.allNotification)
      }else{
        toast("Something Went Wrong")
      }
      
    }).catch((err)=>{
      console.log("ERROR",err)
    })
  }


  const handleItem = (itemData) =>{
    navigate("/action_form" , {state: itemData})
  }



  return (
    <>
     <ToastContainer />
     {
          load && <div className="user_loader">
           <CircularProgress/>
          </div>
        }
      <div className="main_container shadow">
      {
        list.length===0 ? (<>
          <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}>
                    <h5>No Action Required</h5> 
                    <p>Don't Worry.. I will Notify You 😋</p>
                  </div>
        </>):null
      }
      {
        list.map((item, idx)=>{
          return(
            <>
            
            <div key={idx} onClick={()=>{handleItem(item)}} className="action_item border row border-left-warning">
                <div className="col-lg-4">
                    {
                      item.albumId==undefined ? (<>
                          <b>Song Name - </b> {item.albumTitle} <br />
                          <small>Error On Song Details</small>
                      </>):(<>
                          <b>Album Name - </b> {item.albumTitle} <br />
                          <small>Error On Album Details</small>
                      </>)
                    }
                </div>
                <div className="col-lg-8 border-left">
                    <b>Message</b> <br />
                    <small>{item.message}</small>
                </div>
            </div>

            </>
          )
        })
      }
           
            
      </div>
    </>
  )
}

export default Action