import {React, useEffect, useState} from 'react'
import './Dashboard.css'
import { FaDownload, FaCoins } from "react-icons/fa";
import { BsGraphUp } from "react-icons/bs";
import { IoMusicalNotesSharp } from "react-icons/io5";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactApexChart from "react-apexcharts";
import axios from 'axios';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
 

function Dashboard() {


    const [financial, setFinancial] = useState({})
    const [load,setLoad]=useState(false);

    useEffect(() => {
      setLoad(true)
        const userName = localStorage.getItem("user")
        const d = new Date();
        const year = d.getFullYear();

        axios({
        method: "GET",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getStreamingMonthReportForCharts/${userName}/${year}`,
        }).then((res)=>{
          if(res.status===200){
            var barChartStreaming = []
            var barChartMonthListForStreaming = []
            res.data.barChartMonthReport.forEach((val)=>{
              barChartStreaming.push(val.streamming)
              barChartMonthListForStreaming.push(val._id.month)
            })
            setLoad(false)
          }else{
            toast("Something Went Wrong")
          }


        setStreamingBar({
    series: [{
      name: 'Inflation',
      data: barChartStreaming
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          const sum = barChartStreaming.reduce((acc, value) => {
            return acc + value;
          }, 0);
          return ((val*100)/sum).toFixed(2) + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: barChartMonthListForStreaming,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      title: {
        text: 'Streaming Analytics For '+year,
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    },
  
  
        })

        
        
        }).catch((err)=>{
        console.log(err)  
        toast.error("Somthing Went Wrong")
        }) 




        // ------------------------ revenue

        setLoad(true)
        axios({
        method: "GET",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getRevanueMonthStoreReportForCharts/${userName}/${year}`,
        }).then((res)=>{
        if(res.status===200){
          setLoad(false)
          var barChartRevenue = []
          var barChartMonthListForRevenue = []
          res.data.barChartMonthReport.forEach((val)=>{
            barChartRevenue.push(val.revanue)
            barChartMonthListForRevenue.push(val._id.month)
        })
        }else{
          toast("Something Went Wrong")
        }


        setrevenueBar({
    series: [{
      name: 'Inflation',
      data: barChartRevenue
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          const sum = barChartRevenue.reduce((acc, value) => {
            return acc + value;
          }, 0);
          return ((val*100)/sum).toFixed(2) + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: barChartMonthListForRevenue,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      title: {
        text: 'Revenue Analytics For '+year,
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    },
  
  
        })

        
        
        }).catch((err)=>{
        console.log(err)  
        toast.error("Somthing Went Wrong")
        })


        financialReport(userName)
        

    },[])


    const financialReport=(userName)=>{
      const token = localStorage.getItem("user_token")
     
      axios({
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getFinancialReport/${userName}`,
        method: 'get',
        headers: { Authorization : `Bearer ${token}`}
      }).then((res)=>{
        setFinancial(res.data)
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
      })
    }
    

    const [streamingBar, setStreamingBar] = useState({
    series: [{
      name: 'Inflation',
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      title: {
        text: 'Monthly Inflation in Argentina, 2002',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    },
  
  
  }
    )

    const [revenueBar, setrevenueBar] = useState({
    series: [{
      name: 'Inflation',
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }],
    options: {
      chart: {
        height: 350, 
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      title: {
        text: 'Monthly Inflation in Argentina, 2002',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    },
  
  
  }
    )


  return (
    <div className='dashboard'>
        {/* <!-- Page Heading --> */}

        {
          load && <div className="user_loader">
           <CircularProgress/>
          </div>
        }

                           <div className="released_album">
                                
                                <div  >
                                <IoMusicalNotesSharp style={{"fontSize" : "2rem", marginRight:'10px'}} /> Hey <b>{localStorage.getItem("user")},</b> there are <b>{financial.albumCount}</b> album you have released so far
                                </div>

                                
                                
                            </div>
             
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Financial</h1>
                        
                    </div>

                    <div className="row"> 

                   
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Total Earnings</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">₹{financial.earning === undefined ? 0.00 : financial.earning.toFixed(2)}</div>
                                        </div>
                                        <div className="col-auto text-primary" style={{"fontSize" : "2rem"}}>
                                            <BsGraphUp/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Payment Release </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">₹{financial.paymentRelease}</div>
                                        </div>
                                        <div className="col-auto text-success" style={{"fontSize" : "2rem"}}>
                                           <FaCoins/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                     


                        <Link to='/download_credit_notes' className="col-xl-3 col-md-6 mb-4 text-decoration-none">
                            <div className="card bg-primary shadow h-100 py-2">
                                <div className="card-body ">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-light text-uppercase mb-1">
                                                Download </div>
                                            <div className="h5 mb-0 font-weight-bold text-light text-light-800">Credit Notes</div>
                                        </div>
                                        <div style={{"fontSize" : "2rem", "color" : '#1cc88a'}} className="col-auto ">
                                            <FaDownload/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                

                    <div className="d-sm-flex align-items-center justify-content-between mt-3 mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Overview Analytics</h1>
                       
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                        <ReactApexChart options={streamingBar.options} series={streamingBar.series} type="bar" height={350} />
                        </div>
                        <div className="col-lg-6">
                        <ReactApexChart options={revenueBar.options} series={revenueBar.series} type="bar" height={350} />
                        </div>
                    </div>
                    
    </div>
  )
}

export default Dashboard