import React, { useEffect } from "react";
import "./NewSubmisson.css";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Tooltip, Button } from "@material-ui/core";
import { BsFillEyeFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import SubmissionDetails from "./SubmissionDetails";

function NewSubmission() {
  const [allAlbum, setAllAlbum] = useState([]);
  const [showDetails, setShowDetails] = useState(true);
  const [albumId, setAlbumId] = useState();
  const [albumDetails, setAlbumDetails] = useState();

  const nav = useNavigate();
  const getAllAlbum = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getPendingAllAlbum`,
    })
      .then((response) => {
        setAllAlbum(response?.data?.album);

      })
      .catch((err) => {
        console.log(err)
      });
  };
  useEffect(() => {
    getAllAlbum();
    return () => {
      getAllAlbum([]);
    };
  }, []);
  const columns = [
    {
      field: "title",
      headerName: "Album Title",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "1",
      headerName: "User Name",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div>{cellValues.row.user_id.userName}</div>;
      },
    },

    {
      field: "live_date",
      headerName: "Live Date",
      width: 200,
      editable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "client_type",
      headerName: "Client Type",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
      
        return <div>{cellValues.row.user_id.client_type}</div>;
      },
    },
    {
      field: "2",
      headerName: "Quantity",
      type: "number",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div>{cellValues.row.all_song?.length}</div>;
      },
    },

    {
      field: "actions",
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      width: 250, 


      renderCell: (cellValues) => {
      
        return ( 
          <div style={{ display: "flex", gap: 2 }}>
           
              <Tooltip title="View And Manage">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<BsFillEyeFill />}
                  onClick={() => {
                    setAlbumId(cellValues.row._id);
                    setAlbumDetails(cellValues.row);
                    setShowDetails(false);
                  }}
                >
                  View
                </Button>
              </Tooltip>
           
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showDetails ? (
        <>
          <Button
            onClick={() => {
              nav("/admin_home");
            }}
            variant="contained"
            color="primary"
          >
            <BsFillArrowLeftCircleFill />
          </Button>
          <div
            style={{
              height: "60vh",
              width: "100%",
              borderRadius: "7px",
              marginTop: "1rem",
            }}
          >
            <DataGrid
              rows={allAlbum}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50, 100]}
              disableSelectionOnClick
              style={{ color: "white", width: "100%" }}
              getRowId={(row) => row._id}
            
            />
          </div>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              setShowDetails(true);
            }}
            variant="contained"
            color="primary"
          >
            <BsFillArrowLeftCircleFill />
          </Button>

          {!showDetails && <SubmissionDetails albumId={albumId} albumDetails={albumDetails} setShowDetails={setShowDetails} refreshTable={getAllAlbum} />}
        </>
      )}
    </>
  );
}

export default NewSubmission;
