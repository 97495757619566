import React, { useState } from 'react'
import "./ReleaseTable.css"
import ConfirmationModal from "./ConfirmationModal"
import { useFormik } from "formik";
import axios from "axios";
import { MdDownload } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import MatModal from '../NewSubmission/MatModal';
import AlbumEditModal from '../NewSubmission/AlbumEditModal';

function ReleaseDashboard({albumDetails, setShowDetails, refreshTable}) {
    const [showEditModal, setShowEditModal] = useState(false)
    const [slectedsongForEdit, setSelectedSongForEdit] = useState({});
    const [editAlbum, setEditAlbum] = useState(false)
    const [deleteModal,setDeleteModal]=useState(false)

    const formik = useFormik({
        initialValues: {
          song_title: "",
          song_type: "",
          language: "",
          song_desc: "",
          gener: "",
          sub_gener: "",
          mood: "",
          singer: "",
          composer: "",
          director: "",
          star_cast: "",
          explicit: "",
          lyricist: "",
          wav_file: "",
          noc_doc: "",
          upload_mp3: "",
          crbt_start: "",
          crbt_name: "",
        },
        onSubmit: (value) => {
 
        },
      });




      
  const downloadFile = (data, type) => {

 
    if(type === "album_art"){
      axios({
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getSongDetais/${data}`,
        method: "get"
      }).then((res)=>{
        window.open(res.data.url)
      }).catch((err)=>{
        console.log("ERROR==",err)
      })
    }else{
      window.open(data);

    }
  };




  return (
    <>
        <div className="sub_details_main">
        <div className="left">
          {albumDetails.all_song.map((val, i) => {
            return (
              <div key={i}>
                
                
              <div className="edit_btn_div ">
                  <button
                    onClick={() => {
                        setSelectedSongForEdit(val)
                        setShowEditModal(true);
                    }}
                    type="button"
                  >
                    <AiFillEdit />{" "}
                  </button>
                </div>


                <div id={val._id} className="song my-3" key={i}>
                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Song Title
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.song_title}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>



                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Language
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.language}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Genre
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.gener}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Sub Genre
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.sub_gener}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Mood
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.mood}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        type="text"
                        row="10"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.song_desc}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Singer
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.singer}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Composer
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.composer}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Director
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.director}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Star Cast
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.star_cast}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="explicit"
                      className="col-sm-2 col-form-label"
                    >
                      Explicit
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.explicit}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Lyricist
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.lyricist}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-3 col-form-label">
                      CRBT Start Time
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.crbt_start}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      CRBT name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={val.crbt_name}
                        onChange={formik.handleChange}
                        readOnly
                      />
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-10">
                   
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          downloadFile(val.wav_file_url, "wav");
                        }}
                      >
                        <MdDownload /> Wav
                      </button>
                      <button
                        type="button"
                        className="btn btn-success ml-2"
                        onClick={() => {
                          downloadFile(val?.albumId, "album_art");
                        }}
                      >
                        <MdDownload /> Album Art
                      </button>
                      {
                      val.hasOwnProperty('noc_doc_url') &&
                      <button
                        type="button"
                        className="btn btn-info ml-2"
                        onClick={() => {
                          downloadFile(val?.noc_doc_url, "pdf");
                        }}
                      >
                        <MdDownload /> NOC
                      </button>
                      }
                       
                       
                      {
                      val.hasOwnProperty('upload_mp3_url') &&
                      <button
                        type="button"
                        className="btn btn-info ml-2"
                        onClick={() => {
                          downloadFile(val?.upload_mp3_url, "mp3");
                        }}
                      >
                        <MdDownload /> Mp3
                      </button>
                    }
                    
                    

                  

                    </div>
                  </div>

                  <br />
                  <hr />
                </div>
                <br />
              </div>
            );
          })}

          {/* ===end left=== */}
        </div>
        <div className="right">
          {albumDetails.all_song.map((val, i) => {
            return (
              
                <div key={i} className="song_details_list">
                  <a href={`#${val._id}`}>{val.song_title}</a>
                </div>
             
            );
          })}

          <hr />


          <div className="text-center">
            

          <div className='btn-group '>
          <button type="button" onClick={()=>{
             setEditAlbum(true)
              
            }} className="btn btn-warning my-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Album">
              Edit Album
            </button>

          <button type="button" onClick={()=>{setDeleteModal(true)}} className="btn btn-danger my-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Album">
              Delete Permanently
            </button> 
            
          </div>


          </div>
        </div>
      </div>


      {showEditModal && (
        <MatModal closeModal={setShowEditModal} songData={slectedsongForEdit} />
      )}

      {
        editAlbum && (
          <AlbumEditModal closeModal={setEditAlbum} album={albumDetails}/>
        )
      }
      {
        deleteModal && <ConfirmationModal closeModal={setDeleteModal} albumId={albumDetails._id} refreshTable={refreshTable}  setShowDetails={setShowDetails}/>
      }
    </>
  )
}

export default ReleaseDashboard