import React, { useState,useEffect } from 'react'
import './Report.css'
import ReactApexChart from "react-apexcharts";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

function StreamingReport() {

  
  const [bar, setBar] = useState({
    series: [{
      name: 'Inflation',
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      title: {
        text: 'Monthly Inflation in Argentina, 2002',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    },
  
  
  }
  )
  const [pai, setpai] = useState({
    series: [0],
            options: {
              chart: {
                type: 'donut',
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
          
  }
  )
  const currentYear = new Date().getFullYear()
  const [tableData, SetTableData] = useState([]);
  const [storeWiseTotal, SetStoreWiseTotal] = useState({});
  const [storeController, setStoreController] = useState(0);
  const [load, setLoad]=useState(false);
  const [selectYear, setSelectYear] = useState(currentYear); //year api
  const [getYear, setGetYear] = useState([]);
  const [monthTotal, setMonthTotal] = useState([]);

  useEffect(()=>{
    setLoad(true)
    fetchAllData()
  },[selectYear])


  
  const fetchAllData = async() =>{
    const userName = localStorage.getItem("user")
    const yearUrl = `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getYear/${userName}`
    const chartUrl = `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getStreamingMonthReportForCharts/${userName}/${selectYear}`
    const tableUrl = `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getStreaminMonthStoreReportForTable/${userName}/${selectYear}`

    const getYearData = axios.get(yearUrl)
    const getchartData = axios.get(chartUrl)
    const gettableData = axios.get(tableUrl)
    await axios.all([getYearData,getchartData,gettableData]).then(
      axios.spread((...allData)=>{
        const getFinalYear = allData[0]?.data
        const getFinalChart = allData[1]?.data
        const getFinalTable = allData[2]?.data

        setGetYear(getFinalYear?.data)
        chartController(getFinalChart)
        tableController(getFinalTable)
        setLoad(false)

      })
    )
}




const chartController=(allChartData)=>{

  // code for pie chart data asignment  ===========>

    var pieChartRevenueList = []
    var pieChartStoreList = []
    allChartData.pieChartStoreReport.forEach((val)=>{
    pieChartRevenueList.push(val.streamming)
    pieChartStoreList.push(val._id.storeName)
  })

  setpai({
    series: pieChartRevenueList,
            options: {
              labels: pieChartStoreList,
              chart: {
                type: 'donut',
              },
              plotOptions: {
                pie:{
                  donut:{
                    labels:{
                      show: true,
                    }
                  }
                }
              },
              responsive: [{
                breakpoint: 700,
                options: {
                  chart: {
                    width: '100%'
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
          
  })

  // code for bar chart data asignment  ===========>

    var barChartRevenueList = []
    var barChartMonthList = []
    allChartData.barChartMonthReport.forEach((val)=>{
    barChartRevenueList.push(val?.streamming)
    barChartMonthList.push(val?._id?.month)
  })

  setBar({
    series: [{
      name: 'Revenue', 
      data: barChartRevenueList
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          const sum = barChartRevenueList.reduce((acc, value) => {
            return acc + value;
          }, 0);
          return ((val*100)/sum).toFixed(2) + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: barChartMonthList,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            const sum = barChartRevenueList.reduce((acc, value) => {
              return acc + value;
            }, 0);
            return ((val*100)/sum).toFixed(2) + "%";
          },
        }
      
      },
      title: {
        text: 'Monthly Streaming Report',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    },
  
  
  })

}





const tableController=(tableData)=>{
console.log(tableData)
  SetTableData(tableData?.Streamming)
  SetStoreWiseTotal(tableData?.storeWiseTotal)
  setStoreController(tableData?.storeControl)
  

  // var totalArray = []
  // for (const [key, value] of Object.entries(tableData?.monthWiseTotal)) {
  //   totalArray.push(value)
  // }
  setMonthTotal(tableData?.monthWiseTotal)
  
}


  



  return (
    <>
    <ToastContainer/>
    {
          load && <div className="user_loader">
           <CircularProgress/>
          </div>
        }
    <div className="d-flex justify-content-between">
      <h4>Streaming Analytics</h4>
      <div className="form-group col-lg-2">
            <div className="custom-dropdown-control">
                <select id="select_year" value={selectYear} onChange={e=>setSelectYear(e.target.value)} name="select_year" className="custom-select" >
                    <option value="" >--Choose Year--</option>
                    {
                  getYear.map((val,i)=>{
                    return(
                
                      <option key={i} value={val} >{val}</option>
                     
                    )
                  })
                }
                </select>
            </div>
        </div>
    </div>

    

    <div className="state_container">

    {
        tableData?.length !==0 ?
        (<>
        <div className="upper"> 

      
      

          <table className='table table-bordered text-center'>
            <thead className="bg-primary text-light">
              <tr>

                    <th>Months</th>
              {
                  storeController.generalCategory ? (<>
                    <th>GAANA</th>
                    <th>WYNK</th>
                    <th>HUNGAMA</th>
                    <th>JIOSAAVN</th>
                    <th>RESSO</th>
                    <th>AMAZON</th>
                    <th>SPOTIFY</th>
                    <th>APPLE</th>
                    <th>YOUTUBE</th>
                    <th>FACEBOOK</th>
                    <th>TIKTOK</th>
                    <th>SNAP</th>
                  </>):null
                }
                
                
                {
                  storeController.crbtCategory ? (<>
                    <th>AIRTEL</th>
                    <th>IDEA</th>
                    <th>VODA</th>
                    <th>BSNL</th>
                  </>):null
                }

                {
                  storeController.bangladeshCategory ? (<>
                    <th>SHADHIN</th>
                    <th>GPMUSIC</th>
                    <th>VIBE</th>
                    <th>SPLASH</th>
                  </>):null
                }
                
                
                <th>Total</th>
              </tr>
            </thead> 
            <tbody style={{overflow:'scroll'}}>

            {
              
              tableData.map((val,i)=>{
                return(
                  <tr key={i}>
                <td>{val.month}</td>
                  {
                  storeController.generalCategory ? (<>
                    <td>{val['Gaana'] !== undefined ? val.Gaana : 0}</td>
                    <td>{val['Wynk'] !== undefined ? val.Wynk : 0}</td>
                    <td>{val['Hangama'] !== undefined ? val.Hangama : 0}</td>
                    <td>{val['Jiosaavn'] !== undefined ? val.Jiosaavn : 0}</td>
                    <td>{val['Resso'] !== undefined ? val.Resso : 0}</td>
                    <td>{val['Amazon'] !== undefined ? val.Amazon : 0}</td>
                    <td>{val['Spotify'] !== undefined ? val.Spotify : 0}</td>
                    <td>{val['Apple'] !== undefined ? val.Apple : 0}</td>
                    <td>{val['Youtube'] !== undefined ? val.Youtube : 0}</td>
                    <td>{val['Facebook'] !== undefined ? val.Facebook : 0}</td>
                    <td>{val['Tiktok'] !== undefined ? val.Tiktok : 0}</td>
                    <td>{val['Snap'] !== undefined ? val.Snap : 0}</td>
                  </>):null
                }
               
                {
                  storeController.crbtCategory ? (<>
                    <td>{val['Airtel'] !== undefined ? val.Airtel : 0}</td>
                    <td>{val['Idea'] !== undefined ? val.Idea : 0}</td>
                    <td>{val['Voda'] !== undefined ? val.Voda : 0}</td>
                    <td>{val['BSNL'] !== undefined ? val.BSNL : 0}</td>
                  </>):null
                }
                {
                  storeController.bangladeshCategory ? (<>
                    <td>{val['Shadhin'] !== undefined ? val.Shadhin : 0}</td>
                    <td>{val['GPMusic'] !== undefined ? val.GPMusic : 0}</td>
                    <td>{val['Vibe'] !== undefined ? val.Vibe : 0}</td>
                    <td>{val['Splash'] !== undefined ? val.Splash : 0}</td>
                  </>):null
                }
                
                
                <td id={val.month} >{monthTotal[i]}</td>

              </tr>
                )
              })
            }
            <tr>
              <td>Total</td> 
              {
                  storeController.generalCategory ? (<>
                    <td>{storeWiseTotal?.Gaana !== undefined ? storeWiseTotal?.Gaana : 0}</td>
                    <td>{storeWiseTotal?.Wynk !== undefined ? storeWiseTotal?.Wynk : 0}</td>
                    <td>{storeWiseTotal?.Hangama !== undefined ? storeWiseTotal?.Hangama : 0}</td>
                    <td>{storeWiseTotal?.Jiosaavn !== undefined ? storeWiseTotal?.Jiosaavn : 0}</td>
                    <td>{storeWiseTotal?.Resso !== undefined ? storeWiseTotal?.Resso : 0}</td>
                    <td>{storeWiseTotal?.Amazon !== undefined ? storeWiseTotal?.Amazon : 0}</td>
                    <td>{storeWiseTotal?.Spotify !== undefined ? storeWiseTotal?.Spotify : 0}</td>
                    <td>{storeWiseTotal?.Apple !== undefined ? storeWiseTotal?.Apple : 0}</td>
                    <td>{storeWiseTotal?.Youtube !== undefined ? storeWiseTotal?.Youtube : 0}</td>
                    <td>{storeWiseTotal?.Facebook !== undefined ? storeWiseTotal?.Facebook : 0}</td>
                    <td>{storeWiseTotal?.Tiktok !== undefined ? storeWiseTotal?.Tiktok : 0}</td>
                    <td>{storeWiseTotal?.Snap !== undefined ? storeWiseTotal?.Snap : 0}</td>
                  </>):null
                }
              
              {
                  storeController.crbtCategory ? (<>
                    <td>{storeWiseTotal?.Airtel !== undefined ? storeWiseTotal?.Airtel : 0}</td>
                    <td>{storeWiseTotal?.Idea !== undefined ? storeWiseTotal?.Idea : 0}</td>
                    <td>{storeWiseTotal?.Voda !== undefined ? storeWiseTotal?.Voda : 0}</td>
                    <td>{storeWiseTotal?.BSNL !== undefined ? storeWiseTotal?.BSNL : 0}</td>
                  </>):null
                }

                {
                  storeController.bangladeshCategory ? (<>
                    <td>{storeWiseTotal?.Shadhin !== undefined ? storeWiseTotal?.Shadhin : 0}</td>
                    <td>{storeWiseTotal?.GPMusic !== undefined ? storeWiseTotal?.GPMusic : 0}</td>
                    <td>{storeWiseTotal?.Vibe !== undefined ? storeWiseTotal?.Vibe : 0}</td>
                    <td>{storeWiseTotal?.Splash !== undefined ? storeWiseTotal?.Splash : 0}</td>
                  </>):null
                }
             
              
              <td>
                {monthTotal.reduce((acc, val) => acc + val, 0)}
  
              </td>
            </tr>
              
              
            </tbody>
          </table> 
     
      </div>
      <div className="lower">
        <div className="left">
        <ReactApexChart options={bar?.options} series={bar?.series} type="bar" height={350} />
        </div>
        <div className="right">
        <ReactApexChart options={pai?.options} series={pai?.series} type="donut" height={350} />
        </div>
      </div>
  


    
    </>) : (<div style={{
                    height: '80%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}>
                    <h5>No Data Yet</h5> 
                    <p>Don't Worry.. I will Notify You 😋</p>
                  </div>)

    }
    </div>
</>)
}

export default StreamingReport