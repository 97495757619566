import {React, useEffect} from 'react'
import { useNavigate } from "react-router-dom";

function Protected(props) {
    const {Component} = props
    const nav = useNavigate();
    useEffect(()=>{
        let userToken = localStorage.getItem("user_token")
        let role = localStorage.getItem("role")
        if(!userToken && role !== "USER"){
            nav("/");
        }
    },[])



  return (
    <div>
    <Component/>
    </div>
  )
}

export default Protected