import {React, useEffect, useState}  from "react";
import profilePicture from  "../../asset/images/userimage.png"
import {BsFillBellFill} from 'react-icons/bs'
import { useNavigate } from "react-router-dom";
import { SiWebmoney   } from 'react-icons/si';
import { RiLogoutBoxRFill  } from 'react-icons/ri';
import { AiFillNotification,AiOutlineMenuUnfold  } from 'react-icons/ai';
import axios from "axios"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Navbar({handleToggleTop}) {
  const [notification,setNotification] = useState([])
  const [allNotify,setAllNotify] = useState([])
  const [post,setPost]=useState("");
  const nav = useNavigate();
  const logoutHandeler = () =>{ 
      localStorage.removeItem("admin_token_");
      localStorage.removeItem("userId");
      localStorage.removeItem("role");
      localStorage.removeItem("user");
      nav("/")
  }
  const seenNotification = () =>{
    const data = notification.map((val)=>{
      return {"_id" : val._id}
    })
    
    axios({
    url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateBulkNotificationStatus`,
    method: "post",
    data : {data}
    }).then(resp=>{
      if(resp.status===200){
        setNotification([])
      }
    }).catch(err=>{
      console.log(err)
      toast.error("Something Went Wrong")
    })
    
    
  }



 useEffect(()=>{
  getAllNotification()
  getAdminPost()
 
 }, [])


 const getAllNotification=()=>{
  const userId = localStorage.getItem('userId')
  const data = {
    userId : userId
  }
  axios({
    url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllNotification`,
    method: "post",
    data : data
  }).then(resp=>{
    setAllNotify(resp?.data?.allNotification)
    resp?.data?.allNotification.forEach(notifiCount =>{
      if(notifiCount?.status ==="UNSEEN"){
        setNotification(current => [...current, notifiCount]);
      } 
    })
  }).catch(err=>{
    console.log(err)
    toast.error("Something Went Wrong")
  })
 }



 function getAdminPost(){
  axios({
    url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getCurrentAdminPost`,
    method: "get",
  }).then((res)=>{
    setPost(res?.data?.post)
  }).catch((err)=>{
    console.log(err)
    toast.error("Something Went Wrong")
  })
}



    
  return (
    <div>
     <ToastContainer/>
      {/* <!-- Topbar --> */}
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* <!-- Sidebar Toggle (Topbar) --> */}
        <button 
          id="sidebarToggleTop"
          onClick={handleToggleTop}
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <AiOutlineMenuUnfold/>
        </button>

        {/* <!-- Topbar Navbar --> */}
        <ul className="navbar-nav ml-auto">


        <li className="nav-item dropdown no-arrow mx-1" id="post_feed">
                            <a className="nav-link dropdown-toggle" href="/" id="messagesDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <SiWebmoney/>
                                {/* <!-- Counter - Messages --> */}
                                <span className="badge badge-danger badge-counter"></span>
                            </a>
                            {/* <!-- Dropdown - Messages --> */}
                            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="messagesDropdown">
                                <h6 className="dropdown-header">
                                    Posts
                                </h6>
                                {
                                  post && <div className="card" style={{width:'100%'}}>
                                              <img src={post.url} className="card-img-top" alt="..."/>
                                              <div className="card-body">
                                               
                                                <p className="card-text">{post.caption}</p>
                                                <a href={post.link} target="_" className="btn btn-primary">Click Here</a>
                                              </div>
                                            </div>
                                }
                                
                            </div>
                        </li>
         
         

          {/* <!-- Nav Item - Alerts --> */}
          <li className="nav-item dropdown no-arrow mx-1">
            <button
            style={{background: 'transparent', outline:'none', border:'none'}}
              onClick={seenNotification}
              className="nav-link dropdown-toggle"
              id="alertsDropdown"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsFillBellFill />
              {/* <!-- Counter - Alerts --> */}
              <span className="badge badge-danger badge-counter">{notification.length !== 0 ? notification.length : null }</span>
            </button>
            {/* <!-- Dropdown - Alerts --> */}
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown" style={{height: '300px',overflowY:"scroll"}}
            >
            
              <h6 className="dropdown-header" style={{position:'sticky',top:0}} >Notification</h6>
              {
                allNotify.length === 0 ? (<>
                  <div style={{
                    height: '60%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}>
                    <h5>No Notification</h5> 
                    <p>Don't Worry.. I will Notify You 😋</p>
                  </div>
                </>) : null
              
              }

              {
                allNotify.map((val,i)=>{
                  return(

                    <div key={i} className="dropdown-item d-flex align-items-center">
                <div className="mr-3">
                  <div className="icon-circle bg-warning" style={{color:'white'}} >
                    <AiFillNotification/> 
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">{val.date} - {val.time}</div>
                  <span className="font-weight-bold">
                  {
                    val.status==="UNSEEN" ? <b>{val.albumTitle} | {' '}
                    {val.message}</b> : <div style={{color:'#898989'}} >{val.albumTitle} | {val.message}</div>
                  }
                    

                  </span>
                </div>
              </div>


                  )
                })
              }

              
              
            </div>
          </li>

          
          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
               {localStorage.getItem("user")}
              </span>
              <img className="img-profile rounded-circle" src={profilePicture} alt="..." />
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >

              <button
                className="dropdown-item"
                onClick={logoutHandeler}
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <RiLogoutBoxRFill/> Logout
              </button>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
