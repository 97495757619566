import React from "react";
import "./NewSubmisson.css";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import axios from "axios";


function getModalStyle() {
  const top = 50 ;
  const left = 50 ;
 
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: 'scroll',
    height: '80%',
    width: '50%'
  };
}



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AlbumEditModal({ closeModal, album, getSongData }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    closeModal(false);
  };



  const formik = useFormik({
    initialValues: {
      title: album.title,
      release_date: album.release_date,
      live_date: album.live_date,
      film_banner: album.film_banner,
      film_producer: album.film_producer,
      content_type: album.content_type,
    },
    onSubmit: (value) => {
      value.albumId = album._id
      submitUpdate(value);
      formik.resetForm();
      // closeModal.current.click();
      closeModal(false);
    },
  });

  const submitUpdate = (data) => {

    Object.keys(data).forEach(key => {
      if (data[key] === "") {
        delete data[key];
      }
    });

    console.log(data)
    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateAlbumDetails`,
      method: "post",
      data: data,
    })
      .then((res) => {

        if(res.status === 200){
          getSongData()
        }
      })
      .catch((err) => {
        console.log("ERROR : ", err);
      });
  };







  const body = (
    <div style={modalStyle} className={classes.paper}>
      
      <div className="song my-3">

      
                <div className="form-group row">
                  <label
                    htmlFor="title"
                    className="col-sm-2 col-form-label"
                  >
                    Album Title
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="release_date"
                    className="col-sm-2 col-form-label"
                  >
                    Release Date
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      id="release_date"
                      name="release_date"
                      placeholder={album.release_date}
                      onChange={formik.handleChange}
                      value={formik.values.release_date}
                    />
                  </div>
                </div>
                
                
                <div className="form-group row">
                  <label
                    htmlFor="live_date"
                    className="col-sm-2 col-form-label"
                  >
                    Live Date
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      id="live_date"
                      name="live_date"
                      placeholder={album.live_date}
                      onChange={formik.handleChange}
                      value={formik.values.live_date}
                    />
                  </div>
                </div>


                <div className="form-group row">
                  <label
                    htmlFor="film_banner"
                    className="col-sm-2 col-form-label"
                  >
                    Film banner
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="film_banner"
                      name="film_banner"
                      placeholder={album.film_banner}
                      onChange={formik.handleChange}
                      value={formik.values.film_banner}
                    />
                  </div>
                </div>


                <div className="form-group row">
                  <label
                    htmlFor="film_producer"
                    className="col-sm-2 col-form-label"
                  >
                    Film Producer
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="film_producer"
                      name="film_producer"
                      placeholder={album.film_producer}
                      onChange={formik.handleChange}
                      value={formik.values.film_producer}
                    />
                  </div>
                </div>

              
                <div className="form-group row">
                  <label
                    htmlFor="content_type"
                    className="col-sm-2 col-form-label"
                  >
                    Content Type
                  </label>
                  <div className="col-sm-8">
                  <select
                      id="content_type"
                      name="content_type"
                      className="custom-select"
                      onChange={formik.handleChange}
                      value={formik.values.content_type}
                      required
                    >
                      <option value={""}>--Select One--</option>
                      <option value="album">Album</option>
                      <option value="film">Film</option>
                    </select>
                   
                  </div>
                </div>

              
                
              </div>
              <hr />
              <div className=" text-center">
              
              <button
                  type="button"
                  className="btn btn-warning "
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <b>Update</b>
                </button>
              </div>
    </div>
  );

  return (
    <div>

      <Modal
      theme={classes.palette}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        {body}
      </Modal>

    </div>
  );
}
