import React, { useEffect, useState } from 'react'
import './Report.css'
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import axios from 'axios'
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
function DownloadReport() {

  const [selectYear, setSelectYear] = useState("");
  const[year,setYear]=useState([])
  const [selectMonth, setSelectMonth] = useState("");
  const [selectStore, setSelectStore] = useState("");
  const [userAccess, setUserAccess] = useState({});
  const [load, setLoad] = useState(false);


  useEffect(()=>{
    const user = localStorage.getItem('user')
    axios({
      url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/userStoreAccess/${user}`,
      method : 'get',
    }).then((res)=>{
      setUserAccess(res.data)
    }).catch((err)=>{
      toast.error(err.message)
    })
  },[])

  useEffect(()=>{
    const userName = localStorage.getItem('user')
    axios({
      url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getYear/${userName}`,
      method : 'get',
    }).then((res)=>{
      setYear(res.data.data)
    }).catch((err)=>{
      toast.error(err.message)
    })
  },[])

  const handleExportXlsx=()=>{
    setLoad(true)
    const userName = localStorage.getItem("user")
    if( selectYear!=="" && selectMonth!=="" && selectStore!==""){
      const filename = `${selectStore}_${selectYear}_${selectMonth}_report`
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getUserReports/${userName}/${selectStore}/${selectYear}/${selectMonth}`,
   
      }).then(res=>{
        if(res.data.length!==0){
          var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(res.data);
          XLSX.utils.book_append_sheet(wb,ws,filename)
          XLSX.writeFile(wb, filename+".xlsx")
          setLoad(false)
        }else{
          toast.error("No Data Available");
          setLoad(false)
        }
      }).catch(err=>{
        console.log(err)
        setLoad(false)
        toast.error("Somthing Went Wrong");
      })
    }else{
      toast.error("All Fields Are Required");
      setLoad(false)
    }
    
  }

  return (<>
  <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>

{load && (
        <div style={{
          height: '100%',
          width: '100%',
          background: 'rgba(2, 2, 2, 0.429)',
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 99
        }}>
          <CircularProgress />
        </div>
      )}

      <div className="xl_container shadow p-0">
          <div className="header d-flex justify-content-center align-items-center text-light">
                <h1>Download Excel Report</h1>
                
          </div>
          <div className="form_section d-flex justify-content-center align-items-center text-light">
            <div className="download_panel border d-flex justify-content-center align-items-center text-light">
                  <div className="row">
                            
                            <div className="form-group col-lg-3">
                                  <div className="custom-dropdown-control">
                                      <select id="select_year" value={selectStore} onChange={e=>setSelectStore(e.target.value)} name="select_year" className="custom-select" >
                                      <option value="" >--Select stores--</option>
                                      {
                                        userAccess.generalCategory ? (
                                          <>
                                          <option value="Jiosaavn" >JioSaavn</option>
                                      <option value="Wynk" >Wynk</option>
                                      <option value="Spotify" >Spotify</option>
                                      <option value="Apple" >Apple Music</option>
                                      <option value="Gaana" >Gaana</option>
                                      <option value="Amazon" >Amazon Music</option>
                                      <option value="Hangama" >Hangama</option>
                                      <option value="Resso" >Resso</option>
                                      <option value="Youtube" >Youtube</option>
                                      <option value="Facebook" >Facebook</option>
                                      <option value="Tiktok" >Tiktok</option>
                                      <option value="Snap" >Snap</option>
                                          </>
                                        ) : null
                                      }
                                      {
                                        userAccess.crbtCategory ? (<>
                                          <option value="Airtel" >Airtel</option>
                                      <option value="Idea" >Idea</option>
                                      <option value="Voda" >Voda</option>
                                      <option value="BSNL" >BSNL</option>
                                        </>) : null
                                      }
                                      {
                                        userAccess.bangladeshCategory ? (<>
                                          <option value="Shadhin" >Shadhin</option>
                                      <option value="GPMusic" >GP Music</option>
                                      <option value="Vibe" >Vibe</option>
                                      <option value="Splash" >Splash</option>
                                        </>) : null
                                      }
                                     
                             
                                      </select>
                                  </div>
                              </div>
                            <div className="form-group col-lg-3">
                                  <div className="custom-dropdown-control">
                                      <select id="select_year" value={selectYear} onChange={e=>setSelectYear(e.target.value)} name="select_year" className="custom-select" >
                                          <option value="" >--Select Year--</option>
                                          {
                                            year.map((yearValue)=>{
                                              return(
                                                <option key={yearValue} value={yearValue} >{yearValue}</option>
                                              )
                                            })
                                          }
                                          
                                      </select>
                                  </div>
                              </div>
                            <div className="form-group col-lg-3">
                                  <div className="custom-dropdown-control">
                                      <select id="select_year" value={selectMonth} onChange={e=>setSelectMonth(e.target.value)} name="select_year" className="custom-select" >
                                      <option value="" >--Select month--</option>
                                 
                            <option value="Jan" >Jan</option>
                            <option value="Feb" >Feb</option>
                            <option value="Mar" >Mar</option>
                            <option value="Apr" >Apr</option>
                            <option value="May" >May</option>
                            <option value="Jun" >Jun</option>
                            <option value="Jul" >Jul</option>
                            <option value="Aug" >Aug</option>
                            <option value="Sep" >Sep</option>
                            <option value="Oct" >Oct</option>
                            <option value="Nov" >Nov</option>
                            <option value="Dec" >Dec</option>
                                      </select>
                                  </div>
                              </div>
                            <div className="col-lg-3">
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={ <ArrowDownwardIcon />}
                                        onClick={handleExportXlsx}
                                        >
                                        Download
                                        
                                      </Button>
                            </div>
                  </div>
            </div>
          </div>
      </div>
  </>
  )
}

export default DownloadReport