import React, { useState, useEffect } from "react";
import "./Upload.css";
import axios from "axios";
import { RiFolderMusicFill } from "react-icons/ri";
import { FcMusic } from "react-icons/fc";
import CircularProgress from "@material-ui/core/CircularProgress";
import DetailsModal from "./DetailsModal";

function Upload() {
  const [songList, setSongList] = useState([]);
  const [songDetails, setSongDetails] = useState();
  const [albumDetails, setAlbumDetails] = useState();
  const [load, setLoad] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);



  useEffect(() => {
    const token = localStorage.getItem("user_token");

    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllAlbumWithSong`,
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((resp) => {
      let response = resp.data.data;
      setSongList(response);
      setLoad(true);
    });
  }, []);

  return (
    <div>
      {/* <!-- Page Heading --> */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">All Releases</h1>
      </div>

      <div className="upload-container">
        <div className="upload-body  border-left-primary shadow">
          <div className="left">
            <div className="title">
              <h3>Your Album List</h3>
            </div>
            <div className="song-list">
              <div className="spinner">
                {load ? null : <CircularProgress />}
              </div>
              {/* const base64String = btoa(
                  String.fromCharCode(
                    ...new Uint8Array(album.album_art_id.avatar.data)
                  )
                ); */}
              {songList?.length > 0
                ? songList.map((album, idx) => {
                  if(album.status !== "CANCEL")
                   return (
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                          key={idx+9090}
                        >
                    
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`flush-heading${album._id}`}
                            >
                              <div
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapse${album._id}`}
                                aria-expanded="false"
                                aria-controls={`flush-collapse${album._id}`}
                              >
                                <RiFolderMusicFill /> {album.title}{" "}
                                <span
                                  className={`mx-3 badge rounded-pill bg-${
                                    album.status === "PENDING"
                                      ? "warning"
                                      : album.status === "SUCCESS"?
                                      "success" : album.status === "RELEASED" ? "primary" : album.status === "CANCLE" ? "danger" : "info"
                                  }`}
                                >
                                  {album.status === "SUCCESS"? "APPROVED": album.status}
                                </span>
                                <span className="text-muted" style={{fontSize: '15px'}}>
                                {album.release_date}
                                </span>
                              </div>
                            </h2>
                            <div
                              id={`flush-collapse${album._id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`flush-headin${album._id}`}
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <ul>
                                  {album.all_song.map((song, i) => {
                                    return (
                                     
                                        <li key={i+7897}>
                                          <button
                                            onClick={() => {
                                              setToggleModal(true);
                                              setSongDetails(song);
                                              setAlbumDetails(album)
                                            }}
                                            className="btn border"
                                          >
                                            <FcMusic /> {song.song_title} by{" "}
                                            {song.singer} | {song.composer} |{" "}
                                            {song.lyricist}{" "}
                                          </button>
                                        </li>
                                     
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                 
                        
                      
                    );
                  })
                : "No Song Found"}
            </div>
          </div>
         
        </div>
      </div>
      {/* modal ============ */}

      
      {
        toggleModal &&
        <DetailsModal closeModal={setToggleModal} songDetails={songDetails} albumDetails={albumDetails}/>
      }
    </div>
  );
}

export default Upload;
