import React, { useEffect } from 'react'
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { useState } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";




function CreditNotes() {


    const [artistName, setArtistName] = useState([])
    const [load, setLoad] = useState(false);


    useEffect(()=>{
         
        axios({
        url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllArtistName`,
        method: "get",
      }).then((res)=>{
       
        if(res.status === 200 ){
            toast.success(res.data.message)
            let userList = [];
            res.data.forEach((val) => {
              userList.push({ value: val, label: val });
            });
            setArtistName(userList);
        }else{
            toast.error(res.data.message)
        }
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
      })
    },[])


    const validationSchema = yup.object({
        artist_name: yup.string().required("Required Feild"),
        invoiceNo: yup.string().required("Required Feild"),
        PaymentRelease: yup.string().required("Required Feild"),
        credit_notes: yup.string().required("PDF File Is Mandetory"),
    });


    const formik = useFormik({
    initialValues: {
        artist_name: "",
        invoiceNo : "",
        PaymentRelease : "",
    },
    validationSchema : validationSchema,
    onSubmit: (values) => {
      
    var formData = new FormData();
    formData.append('artist_name',values.artist_name)
    formData.append('invoiceNo',values.invoiceNo)
    formData.append('PaymentRelease',values.PaymentRelease)
    formData.append('file',values.credit_notes)
    setLoad(true)
      axios({
        url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/createCreaditNotes`,
        method: "post",
        data: formData
      }).then((res)=>{
         
        setLoad(false)
        if(res.status === 200 ){
           
            toast.success(res.data.message)
            formik.resetForm();
        }else{
            toast.error(res.data.message)
        }
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
        setLoad(false)
      })
      
      
    },
  });


  return (
    
    <>
{load && (
        <div className="mid_loader_user">
          <CircularProgress />
        </div> 
      )}
<ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
toastStyle={{ backgroundColor: "#0a0a0a" }}
/>

<div className="title text-start text-primary" style={{borderBottom: '1px solid white'}}>
        <h4>Send Credit Notes</h4>
      </div> 

      <div className="row">
        <div className="col-lg-3">
        <label htmlFor="artist_name" className=" col-form-label">Send To</label>
        <Select options={artistName} onChange={(e) => formik.setFieldValue('artist_name', e.value)} />
        {/* <select id="artist_name" name="artist_name" className="custom-select" 
                    value={formik.values.artist_name}
                      onChange={formik.handleChange} required>

                      <option value="" >--Choose One--</option>
                      {
                        artistName.map((artist,i)=>{
                            return(
                                <option key={i} value={`${artist}`} >{artist}</option>
                            )
                        })

                      }
                    
                </select> */}
                {formik.errors.artist_name && formik.touched.artist_name ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.artist_name}
                    </div>
                  ) : null}
        </div>
        <div className="col-lg-2">
        <label htmlFor="invoiceNo" className=" col-form-label">Invoice No.</label>
            <input type="text" className="col-lg-12 form-control" id="invoiceNo" name="invoiceNo" value={formik.values.invoiceNo}
                      onChange={formik.handleChange} required/>
                      {formik.errors.invoiceNo && formik.touched.invoiceNo ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.invoiceNo}
                    </div>
                  ) : null}
        </div>
        <div className="col-lg-2">
        <label htmlFor="PaymentRelease" className=" col-form-label">Payment Release</label>
            <input type="text" className="col-lg-12 form-control" id="PaymentRelease" name="PaymentRelease" value={formik.values.PaymentRelease}
                      onChange={formik.handleChange} required/>
                      {formik.errors.PaymentRelease && formik.touched.PaymentRelease ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.PaymentRelease}
                    </div>
                  ) : null}
        </div>
        <div className="col-lg-3">
        <label htmlFor="credit_notes" className=" col-form-label">Upload Pdf</label>
            <input type="file" className="col-lg-12 form-control" id="credit_notes" name="credit_notes" onChange={(e) => {
                      formik.setFieldValue("credit_notes", e.target.files[0]);
                    }} required/>
                    {formik.errors.credit_notes && formik.touched.credit_notes ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.credit_notes}
                    </div>
                  ) : null}
        </div>
        <div className="col-lg-2 position-releative">
        <button className="btn-success btn" type="button" onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}  style={{position:'absolute', bottom: '0'}}  >Upload</button>
        </div>
      </div>


    </>
      
  )
}

export default CreditNotes