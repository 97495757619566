import React from "react";
import "./NewSubmisson.css";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import axios from "axios";


function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: 'scroll',
    height: '80%',
    width: '50%'
  };
}



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function MatModal({ closeModal, songData, getSongData }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open] = React.useState(true);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    closeModal(false);
  };





  const formik = useFormik({
    initialValues: {
      song_title: songData.song_title,
      song_type: songData.song_type,
      language: songData.language,
      song_desc: songData.song_desc,
      gener: songData.gener,
      sub_gener: songData.sub_gener,
      mood: songData.mood,
      singer: songData.singer,
      composer: songData.composer,
      director: songData.director,
      star_cast: songData.star_cast,
      explicit: songData.explicit,
      lyricist: songData.lyricist,
      crbt_start: songData.crbt_start,
      crbt_name: songData.crbt_name,
    },
    onSubmit: (value) => {
      value.songId = songData._id;
      submitUpdate(value);
    },
  });

  const submitUpdate = (data) => {
    
    Object.keys(data).forEach(key => {
      if (data[key] === "") {
        delete data[key];
      }
    });


    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateSongInfo`,
      method: "post",
      data: data,
    })
      .then((res) => {
 
        if(res.status === 200){
          getSongData()
          formik.resetForm();
          closeModal(false);
        }
      })
      .catch((err) => {
        console.log("ERROR : ", err);
      });
  };







  const body = (
    <div style={modalStyle} className={classes.paper}>
      
      <div className="song my-3">
                <div className="form-group row">
                  <label
                    htmlFor="song_title"
                    className="col-sm-2 col-form-label"
                  >
                    Song Title
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="song_title"
                      name="song_title"
                      placeholder={songData.song_title}
                      onChange={formik.handleChange}
                      value={formik.values.song_title}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="song_type"
                    className="col-sm-2 col-form-label"
                  >
                    Song Type
                  </label>
                  <div className="col-sm-8">
                  <select
                      id="song_type"
                      name="song_type"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.song_type}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Original">Original</option>
                      <option value="Cover">Cover</option>
                      <option value="Remix">Remix</option>
                      <option value="Medley">Medley</option>
                      <option value="Live">Live</option>
                      <option value="Karaoke">Karaoke</option>
                      <option value="DJ">DJ</option>
                      <option value="Others">Others</option>
                    </select>
                    
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="language" className="col-sm-2 col-form-label">
                    Language
                  </label>
                  <div className="col-sm-8">
                  <select
                      id="language"
                      name="language"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.language}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Bengali">Bengali</option>
                      <option value="English">English</option>
                      <option value="Santali">Santali</option>
                      <option value="Hindi">Hindi</option>
                      <option value="Gujarati">Gujarati</option>
                      <option value="Marathi">Marathi</option>
                      <option value="Bhojpuri">Bhojpuri</option>
                      <option value="Tamil">Tamil</option>
                      <option value="Telegu">Telegu</option>
                      <option value="Kannada">Kannada</option>
                      <option value="Malayalam">Malayalam</option>
                      <option value="Punjabi">Punjabi</option>
                      <option value="Assamese">Assamese</option>
                      <option value="Oriya">Oriya</option>
                      <option value="Odia">Odia</option>
                      <option value="Manipuri">Manipuri</option>
                      <option value="Haryanvi">Haryanvi</option>
                      <option value="Sanskrit">Sanskrit</option>
                      <option value="Rajasthani">Rajasthani</option>
                      <option value="Sambalpuri">Sambalpuri</option>
                      <option value="Arbic">Arbic</option>
                      <option value="Urdu">Urdu</option>
                      <option value="Banjara">Banjara</option>
                      <option value="Nepali">Nepali</option>
                      <option value="Maithili">Maithili</option>
                      <option value="Garhwali">Garhwali</option>
                      <option value="Sainthili">Sainthili</option>
                      <option value="Himachali">Himachali</option>
                      <option value="Konkani">Konkani</option>
                      <option value="Japanese">Japanese</option>
                      <option value="Awadhi">Awadhi</option>
                      <option value="Naga">Naga</option>
                      <option value="Khasi">Khasi</option>
                      <option value="Dogri">Dogri</option>
                      <option value="Persian">Persian</option>
                      <option value="Pali">Pali</option>
                      <option value="Tibetan">Tibetan</option>
                      <option value="Mandarin">Mandarin</option>
                      <option value="Dogri">Dogri</option>
                      <option value="Chhattisgarhi">Chhattisgarhi</option>
                      <option value="Nagpuri">Nagpuri</option>
                      <option value="Kashmiri">Kashmiri</option>
                      <option value="Sindhi">Sindhi</option>
                      <option value="Kumauni">Kumauni</option>
                      <option value="Marwari">Dogri</option>
                      <option value="Sinhala">Sinhala</option>
                      <option value="Turkish">Turkish</option>
                      <option value="Kokborok">Kokborok</option>
                      <option value="Instrumental">Instrumental</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="gener" className="col-sm-2 col-form-label">
                    Genre
                  </label>
                  <div className="col-sm-8">
                  <select
                      id="gener"
                      name="gener"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.gener}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Film">Film</option>
                      <option value="Devotional">Devotional</option>
                      <option value="Traditional">Traditional</option>
                      <option value="Indie Pop">Indie Pop</option>
                      <option value="Instrumental">Instrumental</option>
                      <option value="Western Classical">
                        Western Classical
                      </option>
                      <option value="Carnatic Classical">
                        Carnatic Classical
                      </option>
                      <option value="Hindustani Classical">
                        Hindustani Classical
                      </option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="English Pop">English Pop</option>
                      <option value="Ghazal">Ghazal</option>
                      <option value="Regional Pop">Regional Pop</option>
                      <option value="Lounge">Lounge</option>
                      <option value="Fusion">Fusion</option>
                      <option value="Folk">Folk</option>
                      <option value="Electronic">Electronic</option>
                      <option value="HipHop">HipHop</option>
                      <option value="Rap">Rap</option>
                      <option value="Rock">Rock</option>
                      <option value="Blues">Blues</option>
                      <option value="Metal">Metal</option>
                      <option value="Jazz">Jazz</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="sub_gener"
                    className="col-sm-2 col-form-label"
                  >
                    Sub Genre
                  </label>
                  <div className="col-sm-8">
                  <select
                      id="sub_gener"
                      name="sub_gener"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.sub_gener}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Acoustic">Acoustic</option>
<option value="Alternative">Alternative</option>
<option value="Baul Geet">Baul Geet</option>
<option value="Bhajan">Bhajan</option>
<option value="Bihu">Bihu</option>
<option value="Borgeet">Borgeet</option>
<option value="Chalisa">Chalisa</option>
<option value="Chhattisgarhi">Chhattisgarhi</option>
<option value="Contemporary">Contemporary</option>
<option value="DJ Mix">DJ Mix</option>
<option value="Dogri">Dogri</option>
<option value="Dogri">Dogri</option>
<option value="Experimental">Experimental</option>
<option value="Geet">Geet</option>
<option value="Geeti">Geeti</option>
<option value="Instrumental">Instrumental</option>
<option value="Indie Folk">Indie Folk</option>
<option value="Kashmiri">Kashmiri</option>
<option value="Karwa Chaut Songs">Karwa Chaut Songs</option>
<option value="Khasi">Khasi</option>
<option value="Kirtan">Kirtan</option>
<option value="Kokborok">Kokborok</option>
<option value="Kumauni">Kumauni</option>
<option value="Langa Geet">Langa Geet</option>
<option value="LoFi">LoFi</option>
<option value="Mandarin">Mandarin</option>
<option value="Mapilla">Mapilla</option>
<option value="Mantra">Mantra</option>
<option value="Marriage Song">Marriage Song</option>
<option value="Marwari">Marwari</option>
<option value="Nazrul Geeti">Nazrul Geeti</option>
<option value="Naat">Naat</option>
<option value="Naga">Naga</option>
<option value="Nepali">Nepali</option>
<option value="Persian">Persian</option>
<option value="Pali">Pali</option>
<option value="Progressive">Progressive</option>
<option value="Psychedelic">Psychedelic</option>
<option value="Qawwals">Qawwals</option>
<option value="Rabindra Sangeet">Rabindra Sangeet</option>
<option value="Rajanikanta">Rajanikanta</option>
<option value="Raksha Bandhan">Raksha Bandhan</option>
<option value="Soundtrack">Soundtrack</option>
<option value="Sinhala">Sinhala</option>
<option value="Sindhi">Sindhi</option>
<option value="Traditional">Traditional</option>
<option value="Tibetan">Tibetan</option>
<option value="Turkish">Turkish</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="mood" className="col-sm-2 col-form-label">
                    Mood
                  </label>
                  <div className="col-sm-8">
                  <select
                      id="mood"
                      name="mood"
                      className="form-select"
                      onChange={formik.handleChange}
                      value={formik.values.mood}
                      required
                    >
                      <option>--Select One--</option>
                      <option value="Romantic">Romantic</option>
                      <option value="Happy">Happy</option>
                      <option value="Sad">Sad</option>
                      <option value="Dance">Dance</option>
                      <option value="Bhangra">Bhangra</option>
                      <option value="Patriotic">Patriotic</option>
                      <option value="Nostalgic">Nostalgic</option>
                      <option value="Inspirational">Inspirational</option>
                      <option value="Enthusiastic">Enthusiastic</option>
                      <option value="Optimistic">Optimistic</option>
                      <option value="Passion">Passion</option>
                      <option value="Pessimistic">Pessimistic</option>
                      <option value="Spiritual">Spiritual</option>
                      <option value="Peppy">Peppy</option>
                      <option value="Philosophical">Philosophical</option>
                      <option value="Mellow">Mellow</option>
                      <option value="Calm">Calm</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="song_desc"
                    className="col-sm-2 col-form-label"
                  >
                    Description
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      row="10"
                      className="form-control"
                      id="song_desc"
                      name="song_desc"
                     
                      onChange={formik.handleChange}
                      value={formik.values.song_desc}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="singer" className="col-sm-2 col-form-label">
                    Singer
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="singer"
                      name="singer"
                     
                      onChange={formik.handleChange}
                      value={formik.values.singer}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="composer" className="col-sm-2 col-form-label">
                    Composer
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="composer"
                      name="composer"
                     
                      onChange={formik.handleChange}
                      value={formik.values.composer}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="director" className="col-sm-2 col-form-label">
                    Director
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="director"
                      name="director"
                    
                      onChange={formik.handleChange}
                      value={formik.values.director}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="star_cast"
                    className="col-sm-2 col-form-label"
                  >
                    Star Cast
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="star_cast"
                      name="star_cast"
                   
                      onChange={formik.handleChange}
                      value={formik.values.star_cast}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="explicit" className="col-sm-4 col-form-label">
                    Explicit
                  </label>
                  <div className="col-sm-8 row">
                    <div className="form-check col">

                      <input
                        className="form-check-input"
                        type="radio"
                        name="explicit" 
                        id="explicit_yes"
                        onChange={(e)=>{formik.setFieldValue('explicit', e.target.value)}}
                        value="yes"
                        checked={formik.values.explicit === "yes"}
                        
                      />
                      <label
                        className="form-check-label"
                        htmlFor="explicit_yes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check col">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="explicit"
                        id="explicit_no"
                        onChange={(e)=>{formik.setFieldValue('explicit', e.target.value)}}
                        value="no"
                        checked={formik.values.explicit === "no"}
                      />
                      <label className="form-check-label" htmlFor="explicit_no">
                        No
                      </label>
                    </div>
               


                    <p className="badge bg-secondary" id="explicit"></p>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="lyricist" className="col-sm-2 col-form-label">
                    Lyricist
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="lyricist"
                      name="lyricist"
                      placeholder={songData.lyricist}
                      onChange={formik.handleChange}
                      value={formik.values.lyricist}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="crbt_start"
                    className="col-sm-3 col-form-label"
                  >
                    CRBT Start Time
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="crbt_start"
                      name="crbt_start"
                      placeholder={songData.crbt_start}
                      onChange={formik.handleChange}
                      value={formik.values.crbt_start}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="crbt_name"
                    className="col-sm-2 col-form-label"
                  >
                    CRBT name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="crbt_name"
                      name="crbt_name"
                      placeholder={songData.crbt_name}
                      onChange={formik.handleChange}
                      value={formik.values.crbt_name}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className=" text-center">
              <button
                  type="button"
                  className="btn btn-warning "
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <b>Update</b>
                </button>
              </div>
    </div>
  );

  return (
    <div>

      <Modal
      theme={classes.palette}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        {body}
      </Modal>

    </div>
  );
}
