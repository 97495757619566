import React,{useEffect, useState} from 'react'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";



function DownloadCreditNotes() {

    const [creditDetails,setCreaditDetails] = useState([]) 

    useEffect(()=>{
         const userName = localStorage.getItem('user')
         axios({
         url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getCreaditNotes/${userName}`,
         method: "get",
       }).then((res)=>{
        
         if(res.status === 200 ){
             setCreaditDetails(res.data.creadit)
         }else{
             toast.error(res.data.message)
         }
       }).catch((err)=>{
         console.log(err)
         toast.error("Something Went Wrong")
       })
     },[])



  return (
    <div>
        <ToastContainer/>

       

                            
        <div className="main_container card shadow mb-4 p-0">
        <div class="card-header py-3">
               <h6 class="m-0 font-weight-bold text-primary">Download Credit Notes</h6>
        </div>
        <div class="card-body">
          {
          creditDetails.length===0 ? (<>
          <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}>
                    <h5>Empty Credit Notes</h5> 
                    <p>Don't Worry.. I will Notify You 😋</p>
                  </div>
        </>):null
      }
        

        {
          creditDetails.map((val,i)=>{
            return(<>
              <div className="action_item action_item border row border-left-primary p-1 align-items-center">
                    <div className="col-lg-6">
                       <b> {val.invoiceNo}</b> <br />
                       {val.createdAt.split('T')[0]}
                    </div>
                    <div className="col-lg-6 text-end">
                      <a href={val.url} className="btn-primary btn">Download</a>
                    </div>
                </div>
            </>)
          })
        }
        </div>  
      </div>

    </div>
  )
}

export default DownloadCreditNotes