import { React, useEffect, useState } from "react";
import "./ApprovedTable.css";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Tooltip, Button } from "@material-ui/core";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SendReleaseDetails from "./SendReleaseDetails";

function ApprovedTable() {
  const [showDetails, setShowDetails] = useState(true);
  const [allApproved, setAllApproved] = useState([]);
  const [album, setAlbum] = useState([]);
  const nav = useNavigate();

  const getAllAlbum = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getSuccessAllAlbum`,
    })
      .then((response) => {
        setAllApproved(response.data?.album);
      })
      .catch((err) => {
        console.log("error=", err);
      });
  };

  useEffect(() => {
    getAllAlbum();
    return () => {
      getAllAlbum([]);
    };
  }, []);

  const columns = [
    {
      field: "title",
      headerName: "Album Title",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "1",
      headerName: "User Name",
      width: 250,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div>{cellValues.row.user_id.userName}</div>;
      },
    },

    {
      field: "live_date",
      headerName: "Live Date",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "client_type",
      headerName: "Client Type",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "2",
      headerName: "Quantity",
      type: "number",
      width: 250,
      editable: true,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div>{cellValues.row.all_song?.length}</div>;
      },
    },

    {
      field: "",
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      width: 200,

      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", gap: 2 }}>
           
              <Tooltip title="View And Manage">
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  onClick={() => {
     
                    setAlbum(cellValues.row);
                    setShowDetails(false);
                  }}
                >
                  Release
                </Button>
              </Tooltip>
            
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showDetails ? (
        <>
          <Button
            onClick={() => {
              nav("/admin_home");
            }}
            variant="contained"
            color="primary"
          >
            <BsFillArrowLeftCircleFill />
          </Button>
          <div
            style={{
              height: "60vh",
              width: "100%",
              borderRadius: "7px",
              marginTop: "1rem",
            }}
          >
            <DataGrid
              rows={allApproved}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50, 100]}
              disableSelectionOnClick
              style={{ color: "white", width: "100%" }}
              getRowId={(row) => row._id}
            />
          </div>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              setShowDetails(true);
            }}
            variant="contained"
            color="primary"
          >
            <BsFillArrowLeftCircleFill />
          </Button>
          <SendReleaseDetails
            album={album}
            refreshTable={getAllAlbum}
            setShowDetails={setShowDetails}
          />
        </>
      )}
    </>
  );
}

export default ApprovedTable;
