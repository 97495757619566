import './App.css';
import Main from './components/Main/Main';
import Login from './components/Login/Login'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Upload from "./components/Upload/Upload";
import UploadForm from './components/Upload/UploadForm';
import Action from './components/Action/Action';
import ActionFormPage from './components/Action/ActionFormPage';
import StramingReport from './components/Report/StramingReport';
import RevenueReport from './components/Report/RevenueReport';
import DownloadReport from './components/Report/DownloadReport';
import Support from './components/Support/Support';
import AdminHome from './components/AdminComponents/AdminHome/AdminHome';
import AdminPanel from './components/AdminComponents/AdminPanel/AdminPanel';
import CreateArtist from './components/AdminComponents/CreateArtist/CreateArtist';
import NewSubmission from './components/AdminComponents/NewSubmission/NewSubmission';
import ApprovedTable from './components/AdminComponents/Approved/ApprovedTable';
import ReleaseTable from './components/AdminComponents/Release/ReleaseTable';
import CancelTable from './components/AdminComponents/Cancel/CancelTable';
import ChangePassword from './components/ChangePassword/ChangePassword';
import CreditNotes from './components/AdminComponents/CreditNotes/CreditNotes';
import DownloadCreditNotes from './components/DownloadCreditNotes/DownloadCreditNotes';
import Protected from './components/Protected';
import UserProfile from './components/AdminComponents/UserProfile/UserProfile';
import AdminProtected from './components/AdminProtected';
import AdminUserProtected from './components/AdminUserProtected';


function App() {

  let role = localStorage.getItem("role")
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<Main />}>

            <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
            <Route path="/song_list" element={<Protected Component={Upload} />} />
            <Route path="/upload_form" element={<Protected Component={UploadForm} />} />
            <Route path="/action" element={<Protected Component={Action} />} />
            <Route path="/action_form" element={<Protected Component={ActionFormPage} />} />
            <Route path="/support" element={<Protected Component={Support} />} />
            <Route path="/streaming_report" element={<Protected Component={StramingReport} />} />
            <Route path="/revenue_report" element={<Protected Component={RevenueReport} />} />
            <Route path="/download_report" element={<Protected Component={DownloadReport} />} />

            <Route path="/download_credit_notes" element={<Protected Component={DownloadCreditNotes} />} />

          </Route>
          <Route element={<AdminProtected Component={AdminPanel} />}>
            <Route path="/admin_home" element={<AdminProtected Component={AdminHome} />} />
            <Route path="/create_artist" element={<AdminUserProtected Component={CreateArtist} />} />
            <Route path="/new_submission" element={<AdminProtected Component={NewSubmission} />} />
            <Route path="/approved" element={<AdminProtected Component={ApprovedTable} />} />
            <Route path="/release" element={<AdminProtected Component={ReleaseTable} />} />
            <Route path="/cancel" element={<AdminProtected Component={CancelTable} />} />
            <Route path="/credit_notes" element={<AdminUserProtected Component={CreditNotes} />} />
            <Route path="/user_profile" element={<AdminUserProtected Component={UserProfile} />} />
            <Route path="/change_password" element={<AdminUserProtected Component={ChangePassword} />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
