import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Tooltip, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "./ConfirmationModal"
import { ADMIN_USER } from "../../utils/role";



function UploadDocumentsTable({ reloadTable, setReloadTable, role }) {
  const [tableData, setTableData] = useState([]);
  const [deletFile, setDeletFile] = useState({})

  const getTableData = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllUplodedRecords`,
    })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  if (reloadTable === true) {
    getTableData();
    setReloadTable(false);
  }


  const closeModal = () => {
    setDeletFile({})
  }


  const columns = [
    // { field: '_id', headerName: 'ID', width: 80 ,headerAlign: "center",},
    {
      field: "storeName",
      headerName: "Store",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "year",
      headerName: "Year",
      width: 170,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "month",
      headerName: "Month",
      type: "number",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt1",
      headerName: "Upload Date",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.createdAt.split('T')[0].split('-')[2]}-
            {cellValues.row.createdAt.split('T')[0].split('-')[1]}-
            {cellValues.row.createdAt.split('T')[0].split('-')[0]}
          </div>
        )
      }
    },
    {
      field: "delete",
      headerName: "Action",
      type: "number",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", gap: 2 }} hidden={role === ADMIN_USER}>
            <Tooltip title="Are you sure ??">
              <IconButton
                aria-label="delete"
                style={{ outline: "none" }}
                onClick={() => {
                  setDeletFile(cellValues.row);
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ height: 500, width: "100%" }}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#0a0a0a" }}
      />
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        style={{ color: "white", width: "100%" }}
        getRowId={(row) => row._id}
        disableColumnFilter={role === ADMIN_USER}
        disableColumnSelector={role === ADMIN_USER}
        disableColumnMenu={role === ADMIN_USER}
      />

      {
        Object.keys(deletFile).length !== 0 && <ConfirmationModal deletFile={deletFile} setReloadTable={setReloadTable} closeModal={closeModal} />
      }


    </div>
  );
}

export default UploadDocumentsTable;
