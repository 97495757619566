import {React, useState} from 'react'
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {AiFillEye} from "react-icons/ai"
import { useNavigate } from "react-router-dom";




function AlbumForm({formData, updateCred}) {

    const [content, setContent] = useState();
    const nav = useNavigate()


    const formik = useFormik({
    initialValues: {
      title: formData?.albumDetails?.title,
      release_date: formData?.albumDetails?.release_date,
      live_date: formData?.albumDetails?.live_date,
      film_banner: formData?.albumDetails?.film_banner,
      film_producer: formData.albumDetails?.film_producer,
      content_type: formData?.albumDetails?.content_type,
    },
    onSubmit: (values) => {
      Object.keys(values).forEach(key => {
        if (values[key] === undefined) {
          delete values[key];
        }
      });
      values.albumId = updateCred.albumId
      values.notificationId = updateCred.notifyId
      axios({
        url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/updateAlbumDetails`,
        method: "post",
        data: values
      }).then((res)=>{
        nav("/action", {state : {
          message : "Great.. Your album has been updated",
          status : "success" 
        }})
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
      })
      
      
    },
  });


  return (
    <div>
     <ToastContainer />
       <div className="form_box">
              <div className="form_container shadow">
                <div className="form-group row">
                  {formik.errors.title && formik.touched.title ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.title}
                    </div>
                  ) : null}
                  <label htmlFor="title" className="col-sm-4 col-form-label">
                    Title 
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      required
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="release_date"
                    className="col-sm-4 col-form-label"
                  >
                    Release Date
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      id="release_date"
                      name="release_date"
                      value={formik.values.release_date}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="live_date"
                    className="col-sm-4 col-form-label"
                  >
                    Live Date
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      id="live_date"
                      name="live_date"
                      value={formik.values.live_date}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  {formik.errors.content_type &&
                  formik.touched.content_type ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.content_type}
                    </div>
                  ) : null}
                  <label
                    htmlFor="content_type"
                    className="col-sm-4 col-form-label"
                  >
                    Content Type 
                  </label>

                  <div className="custom-dropdown-control col-lg-8">
                    <select
                      id="content_type"
                      name="content_type"
                      className="custom-select"
                      onClick={(e) => {
                        setContent(e.target.value);
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.content_type}
                      required
                    >
                      <option value={""}>--Select One--</option>
                      <option value="album">Album</option>
                      <option value="film">Film</option>
                    </select>
                  </div>
                </div>
                {content === "film" ? (
                  <>
                    <div className="form-group row">
                      <label
                        htmlFor="film_banner"
                        className="col-sm-4 col-form-label"
                      >
                        Film Banner
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="film_banner"
                          name="film_banner"
                          onChange={formik.handleChange}
                          value={formik.values.film_banner}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="film_producer"
                        className="col-sm-4 col-form-label"
                      >
                        Film Producer
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="film_producer"
                          name="film_producer"
                          onChange={formik.handleChange}
                          value={formik.values.film_producer}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="form-group row m-0">
                {formik.errors.album_art &&
                  formik.touched.album_art ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.album_art}
                    </div>
                  ) : null}
                  <label
                    htmlFor="album_art"
                    className="col-lg-4 col-form-label"
                  >
                     Album Art
                  </label>
                  {/* <div className="col-lg-6">
                    <input
                      className="form-control btn "
                      type="file"
                      id="album_art"
                      name="album_art"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "album_art",
                          e.target.files[0]
                        );
                      }}
                    />
                  </div> */}
                  <div className="col-lg-8">
                    
                  <button onClick={()=>{window.open(formData.albumDetails.album_art_id.url)}} target="_" className="btn btn-outline-info "><AiFillEye/> view</button>
                  </div>
                  
                </div>
                
                <hr/>

                <div className=" d-flex justify-content-center ">
                  <button
                    type="button"
                    id="next_btn"
                    className="btn btn-success col-lg-3"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
    </div>
  )
}

export default AlbumForm
