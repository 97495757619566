import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";



function getModalStyle() {
  const top = 50 ;
  const left = 50 ;
 
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: '30%',
    width: '30%'
  };
}



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#2d2d2d",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AlbumEditModal({ closeModal, albumId, setShowDetails, refreshTable }) {

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open] = React.useState(true);


  const handleClose = () => {
    closeModal(false);
  };




  const handleDeleteAlbum=()=>{
    axios({
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/deleteAlbum/${albumId}`,
        method: "delete"
      }).then((res)=>{
        if(res.status===200){

            alert("Album Deleted Successfully")
            refreshTable()
            setShowDetails(true)
            closeModal(false);
        }
      }).catch((err)=>{
        console.log("ERROR==",err)
      })
  }



  const body = (
    <div style={modalStyle} className={classes.paper}>
      
      <div className="d-flex justify-content-center align-items-center flex-column" style={{height:'100%', width:'100%'}}>
            <h1>Are You Sure ?</h1>
            <button className="btn btn-danger" onClick={handleDeleteAlbum} >Delete</button>
      </div>
     
    </div>
  );

  return (
    <div>

      <Modal
      theme={classes.palette}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        {body}
      </Modal>

    </div>
  );
}
