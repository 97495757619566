import React, { useEffect } from "react";
import "./login.css";
import logo from "../../asset/images/logo.png";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ADMIN_ROLE_ACCESS } from "../utils/role";




function Login() {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const nav = useNavigate();



  useEffect(() => {
    checkAuth()
  }, [])

  const checkAuth = () => {
    let userToken = localStorage.getItem("user_token")
    let adminToken = localStorage.getItem("admin_token_")
    let role = localStorage.getItem("role")
    console.log(role);
    if (userToken && role === "USER") {
      nav('/dashboard')
    } else if (adminToken && ADMIN_ROLE_ACCESS.includes(role)) {
      nav('/admin_home')
    }
  }

  const onSubmit = (values, submittingObject) => {
    axios
      .post(`${process.env.REACT_APP_DOMAIN_KEY}/api/user/login`, {
        userName: values.userName,
        password: values.password,
      })
      .then(function (response) {


        if (response.status === 201) {
          setError(response?.data?.error);
        } else if (response.status === 200) {
          localStorage.setItem("user", response?.data?.user?.userName);
          localStorage.setItem("userId", response?.data?.user?._id);
          localStorage.setItem("role", response?.data?.user?.role);
          if (response?.data?.user?.role === "USER") {
            localStorage.setItem("user_token", response?.data?.token);
            nav("/dashboard");
          } else if (ADMIN_ROLE_ACCESS.includes(response?.data?.user?.role)) {
            localStorage.setItem("admin_token_", response?.data?.token);
            nav("/admin_home")
          }
        } else {
          setError("Something Went Wrong...");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Somthing Went Wrong")
      });
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required("Please Enter Your Username"),
    password: Yup.string().required("Please Enter Your Password"),
  });

  return (
    <div>
      <div className="main-body">
        <div className="inner-body">
          <div className="left">

            <img src={logo} alt="" />

            {/* <div className="lowerDiv ">
                    <h4>M-TOOL</h4>
                    <h5>CREDIABLE - RELIABLE - SIMPLE</h5>
              </div> */}
          </div>
          <div className="right">
            <Formik
              initialValues={{
                userName: "",
                password: "",
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <>
                  <Form className="form">
                    <h3>Welcome Back,</h3> <br />
                    <small><div className="text-danger">{error}</div></small>
                    <ErrorMessage name="userName" component="div" class="text-danger" />
                    <Field
                      id="userName"
                      name="userName"
                      type="text"
                      required
                      placeholder="Username"
                    />{" "}
                    <br />
                    <ErrorMessage name="password" component="div" class="text-danger" />
                    <Field
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required
                      placeholder="Password"
                    />
                    <br />
                    <div className="text-end">
                      <input type="checkbox" onChange={(e) => { setShowPassword(e.target.checked) }} /> Show Password
                    </div>
                    <br />
                    <button className="ctn" type="submit">
                      Login
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
