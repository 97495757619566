import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import AlbumForm from './AlbumForm';
import SongForm from './SongForm';
import axios from "axios"

function ActionFormPage() {
  const [formData, setFormData] = useState()
  const location = useLocation();
  const nav = useNavigate()
  const hasKey = "albumId" in location.state;


  useEffect(()=>{
    let data = {
      id : location.state._id
    }

    axios({
      url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getNotifyForm`,
      method: "post",
      data : data,
    }).then((res)=>{
      setFormData(res.data)
    }).catch((err)=>{
      console.log("ERROR",err)
    })


  }, [])

  return (
    <>

     <div className="title">
              <button
              type="button"
              className="btn btn-warning mt-3"
              onClick={()=>{nav("/action")}}
              >
              &#8592; Back
            </button>
     </div>

     {
        "albumId" in location.state ? (formData!==undefined && <AlbumForm formData={formData} updateCred={{'albumId': location.state.albumId, notifyId: location.state._id }} />):( 
        formData!==undefined && <SongForm formData={formData} updateCred={{'songId': location.state.songId, notifyId: location.state._id }} />)
        
     }



    </>
  )
}

export default ActionFormPage
