import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TbAccessPoint } from "react-icons/tb";
import { ImPointRight } from "react-icons/im";
import { Button,Box } from '@material-ui/core';
export default function FaqAcc({ title, subtitle, description,link,linkName }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
              >
        <Typography style={{fontSize: '1.2rem', fontStyle: 'normal', fontWeight: '700', color : '#212529ab'}}><TbAccessPoint fontSize={2} size={40} color={'#4e73df'}/>  {title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{flexDirection:'column'}} >
        <Typography style={{
          fontWeight: 'bold',
          width : '100%',
          color : '#515151',
          display : 'flex',
          alignItems : 'center',
          justifyContent : 'flex-start',   
          gap : '10px',
          marginBottom : '5px',
        }}>
          <ImPointRight /> {subtitle}
        </Typography>
        <Typography>
          {description}
        </Typography>
        <Box
        textAlign={"end"}
        width={"100%"}
        >
          <Button 
          variant="contained" 
          color="primary" 
          style={{marginTop : '2rem',backgroundColor : '#4e73df', color : '#fff', fontWeight : 'bold', borderRadius : '9px', padding : '8px 16px', textTransform : 'capitalize'}}
           href={link} 
           target='_'>
              {linkName}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}