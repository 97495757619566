import React, { useState, useEffect } from "react";
import "./CreateArtist.css";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function CreateArtist() {

  const [type, setType] = useState();
  const [userList,setUserList] = useState([])

    useEffect(()=>{
         
        axios({
        url : `${process.env.REACT_APP_DOMAIN_KEY}/api/user/getAllArtistName`,
        method: "get",
      }).then((res)=>{
       
        if(res.status === 200 ){
            toast.success(res.data.message)
        
            setUserList(res?.data)
        }else{
            toast.error(res.data.message)
        }
      }).catch((err)=>{
        console.log(err)
        toast.error("Somthing Went Wrong")
      })
    },[])




  const validationSchema = yup.object({
    userName: yup.string().required("Required Feild").test("userName", "This username has already been registered", function (newUser) {

      if(userList.find(extUser => extUser === newUser) !== undefined){
        return false;
      }else{
        return true;
      }
    }),
    client_type: yup.string().required("Required Feild"),
    email: yup.string().email("Invalid email format").required("Required Feild"),
    password: yup.string().required("Required Feild"),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    phone_number: yup.number().typeError("That doesn't look like a phone number")
    .required('A phone number is required'),
  });


  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      client_type: "",
      label_name: "",
      royalties_name: "",
      designation: "",
      password: "",
      confirm_password: "",
      full_address: "",
      country: "",
      phone_number: "",
      ops_email: "",
      role : "user",
    },
   validationSchema : validationSchema,
    onSubmit: (values) => {
  
  
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_KEY}/api/user/register`,
        data: values,
      })
        .then(function (response) {
       

              if(response.status === 201){
                toast.error(response.data.message);
              }else{
                toast.success(response.data.message);
              }
              
          })
        .catch(function (error) {
          //handle error
       
          toast("Somthing went wrong, please try again");
        });
    },
  });
  return (
    <>
    <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
toastStyle={{ backgroundColor: "#0a0a0a" }}
/>
      <div className="title text-center text-primary">
        <h4>Register New Artist</h4>
        
      </div> 



      <div className=" container_box text-decoration-none col-xl-3 col-md-6 mb-4 ">
          <div className="card border-left-primary h-100 py-2 card-color">
            <div className="card-body">
                
            <form action="">
                <div className="form-group row">
                    <label htmlFor="userName" className="col-sm-4 col-form-label">
                      Username
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        name="userName"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.userName && formik.touched.userName ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.userName}
                    </div>
                  ) : null}
                    </div>
                  </div>


                  <div className="form-group row">
                  <label
                    htmlFor="client_type"
                    className="col-sm-4 col-form-label"
                  >
                    Client Type
                  </label>

                  <div className="custom-dropdown-control col-lg-8">
                    <select
                      id="client_type"
                      name="client_type"
                      className="custom-select"
                      onClick={(e)=>{
                        setType(e.target.value);
                      }}
                        onChange={formik.handleChange}
                      value={formik.values.client_type}
                      required
                    >
                      <option value="">--Select--</option>
                      <option value="Artist">Artist</option>
                      <option value="Label">Label</option>
                    </select>
                    {formik.errors.client_type && formik.touched.client_type ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.client_type}
                    </div>
                  ) : null}
                  </div>
                </div>

                {type === "Artist"? 
                      (
                        <div className="form-group row">
                    <label htmlFor="royalties_name" className="col-sm-4 col-form-label">
                    Royalties Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="royalties_name"
                        name="royalties_name"
                        value={formik.values.royalties_name}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.royalties_name && formik.touched.royalties_name ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.royalties_name}
                    </div>
                  ) : null}
                    </div>
                  </div>
                      ) : (
                        <>
                        <div className="form-group row">
                    <label htmlFor="label_name" className="col-sm-4 col-form-label">
                    Label Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="label_name"
                        name="label_name"
                        value={formik.values.label_name}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.label_name && formik.touched.label_name ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.label_name}
                    </div>
                  ) : null}
                    </div>
                  </div>
                        <div className="form-group row">
                    <label htmlFor="designation" className="col-sm-4 col-form-label">
                    Designation
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="designation"
                        name="designation"
                        value={formik.values.designation}
                        onChange={formik.handleChange}
                        
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="royalties_name" className="col-sm-4 col-form-label">
                    Royalties Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="royalties_name"
                        name="royalties_name"
                        value={formik.values.royalties_name}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.royalties_name && formik.touched.royalties_name ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.royalties_name}
                    </div>
                  ) : null}
                    </div>
                  </div>
                  </>
                      )
                }

                <div className="form-group row">
                    <label htmlFor="full_address" className="col-sm-4 col-form-label">
                      Full Address
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="full_address"
                        name="full_address"
                        value={formik.values.full_address}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.full_address && formik.touched.full_address ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.full_address}
                    </div>
                  ) : null}
                    </div>
                  </div>


                <div className="form-group row">
                    <label htmlFor="country" className="col-sm-4 col-form-label">
                      Country
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.country && formik.touched.country ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.country}
                    </div>
                  ) : null}
                    </div>
                  </div>
                

                <div className="form-group row">
                    <label htmlFor="phone_number" className="col-sm-4 col-form-label">
                      Phone Number
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="phone_number"
                        name="phone_number"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.phone_number && formik.touched.phone_number ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                    </div>
                  </div>
                
              
                
                
                <div className="form-group row">
                    <label htmlFor="email" className="col-sm-4 col-form-label">
                      Email
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="mail"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.email && formik.touched.email ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                    </div>
                  </div>


                  <div className="form-group row">
                    <label htmlFor="ops_email" className="col-sm-4 col-form-label">
                      Other Email
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="ops_email"
                        name="ops_email"
                        value={formik.values.ops_email}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.ops_email && formik.touched.ops_email ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.ops_email}
                    </div>
                  ) : null}
                    </div>
                  </div>
                
                
                  

                <div className="form-group row">
                    <label htmlFor="password" className="col-sm-4 col-form-label">
                      Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.password && formik.touched.password ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.password}
                    </div>
                  ) : null}
                    </div>
                  </div>
                
                
                <div className="form-group row">
                    <label htmlFor="confirm_password" className="col-sm-4 col-form-label">
                     Confirm Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        id="confirm_password"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        
                      />
                       {formik.errors.confirm_password && formik.touched.confirm_password ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.confirm_password}
                    </div>
                  ) : null}
                    </div>
                  </div>
                
                
                <div className="form-group row" hidden>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="role"
                        name="role"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        
                      />
                    </div>
                  </div>




                  <div className="form-group row" style={{margin: "0"}}>
                    <button type="button" onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }} className="btn btn-primary" >Register</button>
                  </div>


          </form>






            </div>
          </div>
        </div>
    </>
  );
}

export default CreateArtist;
